import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  notification,
  message,
  Spin,
  Upload,
} from "antd";
import styles from "./styles.module.css";
import {
  getCategoryListForDropDownMenu,
  postComprehensionImage,
} from "../../../../services/categoryServices";
import {
  getSingleComprehensionDetail,
  postComprehension,
  updateComprehension,
} from "../../../../services/comprehensionServices";

import { UploadOutlined } from "@ant-design/icons";
import {
  categoryColorScheme,
  sortDropDownMenu,
} from "../../../../components/SortAndColorDropDownMenu";
const AddEditComprehension = ({ type, onSuccess, id, callBack }) => {
  const user = localStorage.getItem("userInfo");
  const userInfoObject = JSON.parse(user);
  const [language, setLanguage] = useState();
  const [categoryList, setCategoryList] = useState();
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [form] = Form.useForm();

  const [comprehensionImage, setComprehensionImage] = useState();
  const [oldImageKey, setOldImageKey] = useState();

  const { TextArea } = Input;
  //we get user detail from localstorage and used it to send in payload as created by as user id
  useEffect(() => {
    callGetCategoryList();
  }, [language]);

  useEffect(() => {
    if (id) {
      callGetSingleComprehensionDetail(id);
    }
  }, [id]);
  const callGetSingleComprehensionDetail = async (id) => {
    try {
      setDataLoading(true);
      const singleComprehension = await getSingleComprehensionDetail(id);
      setDataLoading(false);

      // console.log(comprehensionList);
      console.log("Single Record ", singleComprehension);
      if (singleComprehension?.message === "success") {
        form.setFieldsValue({
          slug: singleComprehension?.data?.slug,
          comprehension: singleComprehension?.data?.comprehension,
          category: singleComprehension?.data?.category,
          language: singleComprehension?.data?.language,
        });
        singleComprehension?.data?.media &&
          setComprehensionImage({
            media: singleComprehension?.data?.media,
            mediaKey: singleComprehension?.data?.mediaKey,
          });
        singleComprehension?.data?.media &&
          setOldImageKey(singleComprehension?.data?.mediaKey);
        setLanguage(singleComprehension?.data?.language);
      }
    } catch (error) {
      console.log("error: ", error);
      notification.error({
        message: `${error?.status}: ${error?.statusText}`,
        description: error?.data?.message,
      });
      // setLoading(false);
    }
  };

  const callGetCategoryList = async () => {
    setDataLoading(true);
    const result = await getCategoryListForDropDownMenu({
      query: { language },
    });
    const sortedData = await result?.data?.sort(sortDropDownMenu);
    setCategoryList(sortedData);
    setDataLoading(false);
  };

  // image of comprehension upload code start

  const uploadImage = async ({ formData1 }) => {
    console.log("formData", formData1);

    try {
      const response = await postComprehensionImage(formData1);

      if (response.message !== "success") {
        throw new Error(
          "Something went wrong. Please try again or contact administrator"
        );
      }

      console.log("response image store:", response);
      setComprehensionImage({
        media: response?.data?.media,
        mediaKey: response?.data?.mediaKey,
      });
      message.success("Comprehension Image Uploaded in Database");
    } catch (error) {
      notification.error({
        message: `${error?.status}: ${error?.statusText}`,
        description: error?.data?.message,
      });
    }
  };
  const onChangeFile = (val) => {
    const { fileList: newFileList } = val;

    console.log("data in val:", val);
    console.log("newFileList:", newFileList);

    const formData1 = new FormData();
    formData1.append("image", newFileList?.[0].originFileObj);

    uploadImage({ formData1 });
  };
  // image of comprehension upload code end

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const payload = {};
      payload.slug = values?.slug;
      payload.comprehension = values?.comprehension;
      payload.category = values?.category;
      payload.createdBy = userInfoObject?._id;
      payload.language = values?.language;
      comprehensionImage?.media && (payload.media = comprehensionImage?.media);
      comprehensionImage?.mediaKey &&
        (payload.mediaKey = comprehensionImage?.mediaKey);
      if (oldImageKey) {
        if (comprehensionImage?.mediaKey !== oldImageKey) {
          payload.oldImageKey = oldImageKey;
          alert("this is working");
        }
      }

      // console.log("Payload", payload);

      if (type === "update") {
        // console.log("Update Record Payload ", updatePayload);
        const response = await updateComprehension({ id, payload });
        if (response.message !== "success") {
          throw new Error(
            "Something went wrong. Please try again or contact administrator"
          );
        }
        message.success("Comprehension is updated in a Database");
        onSuccess();
      }
      if (type === "create") {
        const response = await postComprehension(payload);
        if (response.message !== "success") {
          throw new Error(
            "Something went wrong. Please try again or contact administrator"
          );
        }
        message.success("New Comprehension is added in a Database");
        console.log("Comprehension payload", payload);
        form.resetFields();
        setComprehensionImage(null);
      }

      setLoading(false);
    } catch (error) {
      notification.error({
        message: `${error?.status}: ${error?.statusText}`,
        description: error?.data?.message,
      });
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  function handleChange(value) {
    console.log(`selected ${value}`);
    setLanguage(value);
  }

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div>Add New Comprehension</div>
        <Spin spinning={dataLoading}>
          <Form
            form={form}
            name="basic"
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Select Language"
              name="language"
              initialValue={language}
            >
              <Select
                style={{
                  width: 120,
                }}
                onChange={handleChange}
              >
                <Select.Option value="Punjabi">Punjabi </Select.Option>
                <Select.Option value="English">English </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Comprehension Name (Must be Unique)"
              name="slug"
              // initialValue={slug}
              rules={[
                {
                  required: true,
                  message: "Please input Comprehension name!",
                },
              ]}
            >
              <Input
                size="large"
                style={{ borderRadius: "5px" }}
                placeholder="Enter Comprehension Name"
              />
            </Form.Item>
            <Form.Item
              label="Comprehension"
              name="comprehension"
              // initialValue={comprehension}
              rules={[
                {
                  required: true,
                  message: "Please input Comprehension",
                },
              ]}
            >
              <TextArea
                rows={4}
                size="large"
                style={{ borderRadius: "5px" }}
                placeholder="Enter Comprehension"
              />
            </Form.Item>
            <>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div>Comprehension Image</div>
                <div style={{ marginLeft: 5 }}>
                  <Upload
                    accept="image/png, image/jpeg"
                    onChange={(val) => onChangeFile(val)}
                    fileList={[]}
                  >
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                  </Upload>
                </div>
                <div
                  style={{
                    marginLeft: 9,
                  }}
                >
                  {comprehensionImage && (
                    <img
                      src={comprehensionImage?.media}
                      alt="QuestionImage"
                      style={{
                        width: "150px",
                        height: "100px",
                        border: "solid",
                        borderwidth: "1px",
                        borderColor: "blue",
                        borderRadius: 7,
                      }}
                    />
                  )}
                </div>
              </div>
            </>

            <Form.Item
              label=" Category "
              name="category"
              // initialValue={category}
              rules={[
                {
                  required: true,
                  message: "Please select Category",
                },
              ]}
            >
              <Select placeholder="Select a Category">
                {categoryList &&
                  categoryList?.map((option) => (
                    <Select.Option key={option?._id} value={option?._id}>
                      {categoryColorScheme({ name: option?.name, language })}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 0,
                span: 24,
              }}
            >
              <Button loading={loading} type="primary" htmlType="submit">
                Submit
              </Button>
              <Button style={{ marginLeft: "6px" }} onClick={callBack}>
                Return
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </div>
    </div>
  );
};

export default AddEditComprehension;
