import { getMeDetails } from "../../services/userServices";
import {
  USER_LOGIN_FAIL,
  USER_LOGIN_SUCCESS,
} from "../constants/authConstants";

export const updateUserLoginDetails = () => async (dispatch) => {
  try {
    const response = await getMeDetails();
    dispatch({ type: USER_LOGIN_SUCCESS, payload: response.data });

    localStorage.setItem("userInfo", JSON.stringify(response.data));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
