import React from "react";
import AddEditQuiz from "../Components/AddEditQuiz";

const AddNewQuiz = () => {
  return (
    <div>
      <AddEditQuiz type={"create"} />
    </div>
  );
};

export default AddNewQuiz;
