import callApi from "../utils/callApi";

export const getMeDetails = () => {
  // const accessToken = localStorage.getItem("accessToken");
  // get accessToken from browser and send to server to check its authenticity.
  // const headers = { authorization: accessToken };
  return callApi({
    method: "post",
    // query: {
    //   start: 10000,
    //   end: 15000,
    // },
    url: "/api/admin/user/me",
    // headers,
  });
  // call api structure what is demanded is given below callApi(method, url, data, headers)
};

export const getUserList = async ({ query }) => {
  console.log("query", query);
  const result = await callApi({
    method: "get",
    url: "/api/admin/user/userlist",
    query,
  });
  return result;
};

export const grantOrRevokeUserLogin = async (payload) => {
  const result = await callApi({
    method: "put",
    url: "/api/admin/user/grantorrevoke",
    data: payload,
  });
  return result;
};

export const userRegistration = async (payload) => {
  const result = await callApi({
    method: "post",
    url: "/api/admin/auth/register",
    data: payload,
  });
  return result;
};

export const deleteUser = async (payload) => {
  const result = await callApi({
    method: "post",
    url: "/api/admin/user/deleteuser",
    data: payload,
  });
  return result;
};

export const logoutUser = async ({ Headers }) => {
  const result = await callApi({
    method: "post",
    url: "/api/admin/auth/logout",
    Headers,
  });
  return result;
};

export const sendEmail = async ({ payload }) => {
  const result = await callApi({
    method: "post",
    url: "/api/admin/user/invite",
    data: payload,
  });
  return result;
};
