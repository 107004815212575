import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Table,
  Space,
  Popconfirm,
  Pagination,
  notification,
  message,
  Tooltip,
} from "antd";
import {
  deleteQuiz,
  getQuizList,
  setQuizOnlineOffline,
} from "../../../services/quizServices";
import styles from "./styles.module.css";

import UpdateCustomIdQuiz from "../../../components/UpdateCustomIdQuiz";
const QuizList = ({ category, language, showCustomId }) => {
  // Quiz Table Codding Start
  const [quizList, setQuizList] = useState({ data: [], totalRecords: 0 });
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(10);
  const [customIdVisible, setcustomIdVisible] = useState(false);
  const [updateCustomIdModal, setupdateCustomIdModal] = useState(false);
  const [currentCustomId, setCurrentCustomId] = useState();
  const query = {};
  useEffect(() => {
    callGetQuizList();
  }, [start, limit]);
  console.log("custom id visible", showCustomId);

  useEffect(() => {
    if (showCustomId) setcustomIdVisible(showCustomId);
  }, [showCustomId]);
  const callGetQuizList = async () => {
    setLoading(true);
    query.start = start;
    query.limit = limit;
    query.type = "boolean";
    if (category) query.category = category;
    if (language) query.language = language;
    const result = await getQuizList({
      query,
    });
    setLoading(false);

    setQuizList(result);
  };
  const deleteQuizFunction = async (id) => {
    const deleted = await deleteQuiz(id);
    callGetQuizList();
  };

  const callSetQuizOnlineOffline = async (record) => {
    try {
      // setLoading(true);

      console.log("grant revoke record", record);
      const payload = {
        is_active: record?.is_active,
      };
      console.log("grant revoke payload", payload);
      const response = await setQuizOnlineOffline({ id: record?.id, payload });
      if (response?.message === "success")
        message.success("Quiz Online Status Changed");
      else message.success("Something went Wrong");

      callGetQuizList();
    } catch (error) {
      notification.error({
        message: `${error?.status}: ${error?.statusText}`,
        description: error?.data?.message,
      });
    }
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "customId",
      key: "customId",
      render: (value, record) => (
        <div
          style={{
            backgroundColor: "blue",
            textAlign: "center",
            cursor: "pointer",
            color: "white",
            borderRadius: 4,
            padding: 5,
            fontWeight: "700",
            display: customIdVisible ? "block" : "none",
          }}
          onClick={() => {
            setCurrentCustomId(record);
            setupdateCustomIdModal(true);
          }}
        >
          {value}
        </div>
      ),
    },
    {
      title: "Image",
      dataIndex: "media",
      key: "media",
      render: (value, record) => (
        <img
          src={
            value
              ? value
              : "https://quiz-app1.s3.ap-south-1.amazonaws.com/media/images/QuizDefaultImage.jpg"
          }
          alt="Login"
          className={styles.image}
        />
      ),
    },
    {
      title: "Quiz Name",
      dataIndex: "name",
    },
    {
      title: "Quiz Category",
      dataIndex: "category",
      sorter: {
        compare: (a, b) => a.category - b.category,
        multiple: 3,
      },
      render: (text, record) => record?.category?.name,
    },
    {
      title: "Total Questions",
      dataIndex: "questions",
      key: "questions",
      render: (text) => text?.length || 0,
    },
    {
      title: "Lives",
      dataIndex: "lives",
      sorter: {
        compare: (a, b) => a.lives - b.lives,
        multiple: 2,
      },
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "_id",
      render: (text, record) =>
        record?.is_active === true ? "Online" : "Offline",
    },
    {
      title: "Language",
      dataIndex: "language",
      key: "language",
      filters: [
        {
          text: "English",
          value: "English",
        },
        {
          text: "Punjabi",
          value: "Punjabi",
        },
      ],
      onFilter: (value, record) => record?.language.indexOf(value) === 0,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          {record?.is_active === false ? (
            <Popconfirm
              title="Are you sure to make it Online"
              onConfirm={() =>
                callSetQuizOnlineOffline({
                  id: record?._id,
                  is_active: true,
                })
              }
              okText="Confirm"
              cancelText="Cancel"
              placement="leftTop"
              okButtonProps={{
                danger: true,
              }}
            >
              <Tooltip
                placement="topLeft"
                title="This will diplay quiz to all users"
              >
                <Button type="danger" className={styles.button}>
                  Offline
                </Button>
              </Tooltip>
            </Popconfirm>
          ) : (
            <Tooltip
              placement="topLeft"
              title="This will hide quiz from all users"
            >
              <Button
                className={styles.button}
                style={{
                  backgroundColor: "green",
                  color: "white",
                  fontWeight: "normal",
                }}
                onClick={() =>
                  callSetQuizOnlineOffline({
                    id: record?._id,
                    is_active: false,
                  })
                }
              >
                Online
              </Button>
            </Tooltip>
          )}

          <Popconfirm
            title="Are you sure to delete this task?"
            onConfirm={() => deleteQuizFunction(record._id)}
            okText="Confirm"
            cancelText="Cancel"
            placement="leftTop"
            okButtonProps={{
              danger: true,
            }}
          >
            <Tooltip
              placement="topLeft"
              title="This will delete question from this quiz"
            >
              <Button type="primary" danger className={styles.button}>
                Delete
              </Button>
            </Tooltip>
          </Popconfirm>

          <Link to={`/quizlist/${record._id}`}>
            <Tooltip
              placement="topRight"
              title="Add or view questions and comprehension questions"
            >
              <Button type="primary" className={styles.button}>
                Add/View Questions
              </Button>
            </Tooltip>
          </Link>
        </Space>
      ),
    },
  ];

  // function onTableChange(pagination, filters, sorter, extra) {
  //   console.log("params", pagination, filters, sorter, extra);
  // }

  // Quiz Table Codding End

  const onShowSizeChange = (current, pageSize) => {
    setLimit(pageSize);
    // console.log(current, pageSize);
  };
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div>List of quiz</div>
        <div>
          <Table
            columns={columns}
            loading={loading}
            dataSource={quizList?.data}
            // onChange={onTableChange}
            pagination={false}
            size="middle"
            rowKey={(record) => record?._id}
          />
          <Pagination
            total={quizList?.totalRecords}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            showSizeChanger
            pageSize={limit}
            onShowSizeChange={onShowSizeChange}
            current={start / limit + 1}
            onChange={(page, pageSize) => {
              setStart((page - 1) * limit);
            }}
          />
        </div>
        <div>
          {updateCustomIdModal ? (
            <UpdateCustomIdQuiz
              visible={updateCustomIdModal}
              handleCancel1={() => {
                setupdateCustomIdModal(false);
              }}
              defaultId={currentCustomId?.customId}
              id={currentCustomId?._id}
              allDone={() => callGetQuizList()}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default QuizList;
