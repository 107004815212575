import {
  Form,
  Input,
  Button,
  Select,
  notification,
  message,
  Tooltip,
  Radio,
  DatePicker,
} from "antd";
import React, { useState } from "react";
import { postPoll } from "../../../../services/pollServices";

import styles from "./styles.module.css";
const AddUpdatePoll = ({ type }) => {
  const [form] = Form.useForm();

  const user = JSON.parse(localStorage.getItem("userInfo"));
  const [optionsShow, setOptionsShow] = useState(true);
  const [trueFalse, setTrueFalse] = useState();
  const { Option } = Select;
  const onFinish = async (values) => {
    try {
      //   setLoading(true);
      console.log("values", values);
      const payload = {
        title: values.title,
        type: values.type,
        option1: values.option1,
        option2: values.option2,
        option3: values.option3,
        option4: values.option4,
        validFrom: values.validFrom?.toISOString(),
        validTo: values.validTo?.toISOString(),
        createdby: user._id,
      };

      console.log("payload values", payload);
      //   if (type === "update") {
      //     const response = await updateQuiz({ id, payload });
      //     if (response.message !== "success") {
      //       throw new Error(
      //         "Something went wrong. Please try again or contact administrator"
      //       );
      //     }
      //     message.success(" Quiz Updated in a Database");
      //   }

      if (type === "create") {
        const response = await postPoll(payload);
        if (response.message !== "success") {
          throw new Error(
            "Something went wrong. Please try again or contact administrator"
          );
        }
        form.resetFields();
        message.success("New Poll is added in a Database");
      }

      //   if (onSuccess) onSuccess();
      //   callGetCategoryList();
      //   setLoading(false);
    } catch (error) {
      console.log("error: ", error);
      notification.error({
        message: `${error?.status}: ${error?.statusText}`,
        description: error?.data?.message,
      });
      //   setLoading(false);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const showHide = (e) => {
    if (e.target.value === "options") {
      setOptionsShow(true);
      // setOptionsShow(false);
    } else if (e.target.value === "boolean") {
      setOptionsShow(false);
      // setOptionsShow(false);
    }
  };
  const setTrueFalseFunction = (toChange, values) => {
    console.log("True false value", values);
    const formValues = form.getFieldsValue();
    if (values === "True") {
      form.setFieldsValue({ ...formValues, [toChange]: "False" });
    } else {
      form.setFieldsValue({ ...formValues, [toChange]: "True" });
    }
    setTrueFalse(values);
  };
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.form}>
          <Form
            form={form}
            labelCol={{
              span: 4,
            }}
            wrapperCol={{
              span: 24,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Select Language"
              name="language"
              initialValue="Punjabi"
            >
              <Select
                style={{
                  width: 120,
                }}
                // onChange={handleChange}
              >
                <Select.Option value="Punjabi">Punjabi </Select.Option>
                <Select.Option value="English">English </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Poll Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please input Poll Title",
                },
              ]}
            >
              <Input
                size="large"
                style={{ borderRadius: "5px" }}
                placeholder="Enter Poll Title"
              />
            </Form.Item>
            <Form.Item
              name="type"
              label="Question Type"
              initialValue="options"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please select Question Type",
              //   },
              // ]}
            >
              <Radio.Group onChange={showHide} defaultValue="options">
                <Tooltip
                  title="By Default selected for multiple choice questions"
                  placement="topLeft"
                >
                  <Radio value="options">Options</Radio>
                </Tooltip>
                <Tooltip
                  title="Select this for True/False questions."
                  placement="topLeft"
                >
                  <Radio value="boolean">True/False</Radio>
                </Tooltip>
              </Radio.Group>
            </Form.Item>
            {optionsShow ? (
              <>
                <Form.Item
                  name="option1"
                  label="A"
                  rules={[
                    {
                      required: true,
                      message: "Please enter option",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="option2"
                  label="B"
                  rules={[
                    {
                      required: true,
                      message: "Please enter option",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="option3"
                  label="C"
                  rules={[
                    {
                      required: true,
                      message: "Please enter option",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="option4"
                  label="D"
                  rules={[
                    {
                      required: true,
                      message: "Please enter option",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </>
            ) : (
              <>
                <Form.Item
                  name="option1"
                  label="A"
                  rules={[
                    {
                      required: true,
                      message: "Please select option",
                    },
                  ]}
                >
                  <Select
                    size="large"
                    style={{ borderRadius: "5px" }}
                    onChange={(value) => setTrueFalseFunction("option2", value)}
                  >
                    <Option value="True">True</Option>
                    <Option value="False">False</Option>
                  </Select>
                </Form.Item>
                <Form.Item name="option2" label="B">
                  <Select
                    size="large"
                    style={{ borderRadius: "5px" }}
                    onChange={(value) => setTrueFalseFunction("option1", value)}
                  >
                    <Option value="True">True</Option>
                    <Option value="False">False</Option>
                  </Select>
                </Form.Item>
              </>
            )}
            <Form.Item label="Start Date" name="validFrom">
              <DatePicker />
            </Form.Item>

            <Form.Item label="End Date" name="validTo">
              <DatePicker />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 0,
                span: 24,
              }}
            >
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AddUpdatePoll;
