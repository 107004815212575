import React, { useState, useCallback, useRef, useEffect } from "react";
import {
  Button,
  Popconfirm,
  Popover,
  Table,
  Space,
  notification,
  message,
} from "antd";
import styles from "./styles.module.css";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import {
  deleteQuestionFromQuiz,
  updateSortedQuestionToQuiz,
} from "../../services/quizServices";

const type = "DraggableBodyRow";

const DraggableBodyRow = ({
  index,
  moveRow,
  className,
  style,

  ...restProps
}) => {
  const ref = useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? " drop-over-downward" : " drop-over-upward",
      };
    },
    drop: (item) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ""}`}
      style={{ cursor: "move", ...style }}
      {...restProps}
    />
  );
};

const columns = [
  {
    title: <div className={styles.heading}>Id </div>,
    key: "index",
    render: (text, record, index) => (
      <div className={styles.heading}>{index + 1}</div>
    ),
    width: 55,
  },
  {
    title: "Name",
    dataIndex: "title",
    key: "title",
    render: (value, record) =>
      record?.title?.type === "text" ? (
        record?.title?.text
      ) : (
        <img
          src={record?.title?.text}
          alt="Login"
          style={{ height: "80px", width: "120px" }}
        />
      ),
  },
  {
    title: "Comprehension",
    dataIndex: "comprehension",
    key: "comprehension",
    ellipsis: {
      showTitle: false,
    },
    width: "30%",

    render: (comprehension) => (
      <Popover
        title="Comprehension"
        content={comprehension?.comprehension}
        overlayStyle={{ maxWidth: "700px" }}
      >
        <Button>{comprehension?.slug}</Button>
      </Popover>

      // <Tooltip placement="topLeft" title={comprehension?.comprehension}>
      //   {comprehension?.slug}
      // </Tooltip>
    ),
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    width: "10%",
  },
  {
    title: "Action",
    key: "action",
    width: "12%",
    render: (text, record) => (
      <Space size="middle">
        <Popconfirm
          title="Are you sure to delete this task?"
          onConfirm={() => callDeleteQestionFromQuiz(record._id)}
          okText="Confirm"
          cancelText="Cancel"
          placement="leftTop"
          okButtonProps={{
            danger: true,
          }}
        >
          <Button type="primary" danger>
            Delete
          </Button>
        </Popconfirm>
      </Space>
    ),
  },
];

let idglobal;
let callBackGlobal;
const callDeleteQestionFromQuiz = async (questionId) => {
  // console.log("Quiz id", params.id);
  // console.log("Question id to delete", idToDelete);

  try {
    const result = await deleteQuestionFromQuiz({
      id: idglobal,

      questionId: { questionId },
    });
    if (result.message === "success") {
      callBackGlobal();
    }
  } catch (error) {
    notification.error({
      message: `${error?.status}: ${error?.statusText}`,
      description: error?.data?.message,
    });
    // setLoading(false);
  }
};

const DragSortingTable = ({ id, tableData, callBack }) => {
  const [data, setData] = useState(tableData);

  idglobal = id;
  callBackGlobal = callBack;

  useEffect(() => {
    setData(tableData);
  }, [tableData]);

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };
  const callUpdateQuiz = async (newIds) => {
    try {
      // setLoading(true);
      const payload = {
        questionsIds: newIds,
      };

      const response = await updateSortedQuestionToQuiz({ id, payload });
      if (response.message !== "success") {
        throw new Error(
          "Something went wrong. Please try again or contact administrator"
        );
      }
      message.success("Sorted Quiz is Updated in a Database");
      callBack();
      // callGetCategoryList();
      // setLoading(false);
    } catch (error) {
      console.log("error: ", error);
      notification.error({
        message: `${error?.status}: ${error?.statusText}`,
        description: error?.data?.message,
      });
      // setLoading(false);
    }
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = data[dragIndex];
      setData(
        update(data, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      );
      const newIds = update(data, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow],
        ],
      }).map((item) => item._id);
      // call update quiz
      callUpdateQuiz(newIds);
      console.log("updated data: ", newIds);
    },
    [data]
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <Table
        columns={columns}
        dataSource={data}
        components={components}
        onRow={(record, index) => ({
          index,
          moveRow,
        })}
        rowKey={(record) => record?._id}
        pagination={false}
      />
    </DndProvider>
  );
};

export default DragSortingTable;
