// import { Button, message, notification } from "antd";

// import React from "react";

// import { LogoutComponent } from "../../Components/LogoutComponent";
// import styles from "./styles.module.css";

// const Profile = () => {
//   const user = localStorage.getItem("userInfo");
//   const userInfoObject = JSON.parse(user);

//   const

//   return (
//     <div className={styles.main}>
//       <div className={styles.container}>
//         <p>User Name : {userInfoObject?.name}</p>
//         <p>User Email : {userInfoObject?.email}</p>
//         <p>User Role: {userInfoObject?.role}</p>
//         <Button
//           onClick={() => {
//             onLogoutHandler();
//           }}
//         >
//           Log Out
//         </Button>
//         {LogoutComponent()}
//       </div>
//     </div>
//   );
// };

// export default Profile;

import { Button, message, notification } from "antd";

import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { USER_LOGOUT } from "../../../../redux/constants/authConstants";
import { logoutUser } from "../../../../services/userServices";
import styles from "./styles.module.css";

const Profile = () => {
  const user = localStorage.getItem("userInfo");
  const userInfoObject = JSON.parse(user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const callLogoutUser = async () => {
  //   try {
  //     const response = await logoutUser({
  //       Headers: { authorization: user?.refreshToken },
  //     });
  //     if (response.message === "success") {
  //       message.success("You are logout");
  //       dispatch({ type: USER_LOGOUT });
  //       localStorage.clear();
  //       // <Redirect to="/login" />;
  //       //move to home page by link
  //       navigate("/auth/login");
  //     }
  //   } catch (error) {
  //     notification.error({
  //       message: `${error?.status}: ${error?.statusText}`,
  //       description: error?.data?.message,
  //     });
  //   }
  // };
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <p>User Name : {userInfoObject?.name}</p>
        <p>User Email : {userInfoObject?.email}</p>
        <p>User Role: {userInfoObject?.role}</p>
        {/* <Button onClick={() => callLogoutUser()}>Log Out</Button> */}
      </div>
    </div>
  );
};

export default Profile;
