import callApi from "../../utils/callApi";
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
} from "../constants/authConstants";

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST });

    const headers = { "Content-type": "application/json" };

    const data = await callApi({
      method: "post",
      url: "/api/admin/auth/login",
      data: { email, password },
      headers,
    });
    dispatch({ type: USER_LOGIN_SUCCESS, payload: data });

    localStorage.setItem("userInfo", JSON.stringify(data.user));
    //userInfo is a json object and it need to convert it in string. that's the reason we use stringify in it.
    localStorage.setItem("accessToken", data.access_token);
    localStorage.setItem("refreshToken", data.refresh_token);
    //accessToken and refreshToken is already a string. so we can store it in local storage as it is.
  } catch (error) {
    console.log("beofre dispatch: ", error);
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: error && error.data.message ? error.data.message : error.message,
    });
  }
};

export const register = (email, name, password) => async (dispatch) => {
  try {
    dispatch({ type: USER_REGISTER_REQUEST });

    const headers = { "Content-type": "application/json" };

    const data = await callApi(
      "post",
      "/api/admin/auth/register",
      { email, name, password },
      headers
    );
    dispatch({ type: USER_REGISTER_SUCCESS, payload: data });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
