import React, { useEffect, useState } from "react";
import { Button, Modal, Spin, Tooltip } from "antd";

import AddQuestionModel from "../Components/AddQuestionModel";
import DragSortingTable from "../../../components/DraggableTable";
import styles from "./styles.module.css";
import { useNavigate, useParams } from "react-router-dom";

import {
  deleteQuestionFromQuiz,
  getQuizQuestionList,
  getSingleQuizDetail,
} from "../../../services/quizServices";
import AddEditQuiz from "../Components/AddEditQuiz";
import AddComprehensionQuestionToQuiz from "../../Comprehension/Components/AddComprehensionQuestionToQuiz";

const AddQuestionToQuiz = () => {
  let params = useParams();
  const [visible, setVisible] = useState(false);
  const [defaultCategory, setDefaultCategory] = useState();
  const [quizDetail, setQuizDetail] = useState();
  const [questionList, setQuestionList] = useState({
    data: [],
    totalRecords: 0,
  });
  const [editModelVisible, setEditModelVisible] = useState(false);
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(30);

  const [loading, setLoading] = useState();
  const [loadingDetail, setLoadingDetail] = useState();

  useEffect(() => {
    callgetSingleQuizDetail();
    callGetQuizQuestionList();
  }, [start, limit]);

  const navigate = useNavigate();
  //Add Comprehension Question to Quiz Model declaration start
  const [comprehensionModelVisible, setComprehensionModelVisible] =
    useState(false);
  //Add Comprehension Question to Quiz Model declaration end

  const callGetQuizQuestionList = async () => {
    setLoading(true);
    const result = await getQuizQuestionList({
      id: params.id,
    });

    console.log(result);
    setQuestionList(result);

    setLoading(false);
  };

  const callgetSingleQuizDetail = async () => {
    setLoadingDetail(true);
    const result = await getSingleQuizDetail(params.id);
    setQuizDetail(result?.data);

    setDefaultCategory(result?.data?.category._id);
    setLoadingDetail(false);
  };

  const refreshAndClose = ({ callbackAction }) => {
    console.log("callback action", callbackAction);
    if (callbackAction === "update") {
      setEditModelVisible(false);
      callgetSingleQuizDetail();
    }
    if (callbackAction === "QuestionToQuiz") {
      setComprehensionModelVisible(false);
      callgetSingleQuizDetail();
      callGetQuizQuestionList();
    }
    if (callbackAction === "cancel") {
      setComprehensionModelVisible(false);
      callgetSingleQuizDetail();
      callGetQuizQuestionList();
    }
  };
  const onShowSizeChange = (current, pageSize) => {
    setLimit(pageSize);
    // console.log(current, pageSize);
  };
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div style={{ fontWeight: "700", fontSize: 16, color: "red" }}>
          Quiz Detail Screen
        </div>
        <Spin spinning={loadingDetail}>
          <div>
            <div className={styles.imageAndContent}>
              <div
                style={{
                  justifyContent: "center",
                  alignSelf: "center",
                  margin: 4,
                }}
              >
                {quizDetail?.media && (
                  <img
                    src={quizDetail?.media}
                    alt="QuestionImage"
                    style={{
                      width: "200px",
                      height: "140px",
                      border: "solid",
                      borderwidth: "0.1",
                      borderColor: "blue",
                      borderRadius: 7,
                    }}
                  />
                )}
              </div>
              <div>
                <div>
                  Quiz Name: <b> {quizDetail?.name}</b>
                </div>
                <div>
                  Description: <b>{quizDetail?.description}</b>{" "}
                </div>
                <div>
                  Language: <b>{quizDetail?.language}</b>{" "}
                </div>
                <div>
                  Created By: <b>{quizDetail?.createdby?.name}</b>{" "}
                </div>
                <div>
                  Category: <b>{quizDetail?.category?.name}</b>{" "}
                </div>
                <div>
                  Lives: <b>{quizDetail?.lives}</b>{" "}
                </div>

                <div>
                  Total Questions: <b>{quizDetail?.questions?.length}</b>{" "}
                </div>
              </div>
            </div>
            <div>
              <Tooltip placement="topLeft" title="Just go one step back.">
                <Button onClick={() => navigate(-1)} className={styles.button}>
                  Back
                </Button>
              </Tooltip>
              <Tooltip placement="topLeft" title="Edit quiz title etc.">
                <Button
                  type="primary"
                  className={styles.button}
                  onClick={() => setEditModelVisible(true)}
                >
                  Edit
                </Button>
              </Tooltip>
              <Tooltip
                placement="topLeft"
                title="This will help to add question to quiz."
              >
                <Button
                  type="primary"
                  onClick={() => setVisible(true)}
                  className={styles.button}
                >
                  Add Question in Quiz
                </Button>
              </Tooltip>
              <Tooltip
                placement="topLeft"
                title="Add comprehension questions to quiz."
              >
                <Button
                  type="primary"
                  onClick={() => setComprehensionModelVisible(true)}
                  className={styles.button}
                >
                  Add Comprehension in Quiz
                </Button>
              </Tooltip>
            </div>
          </div>
        </Spin>
        <div>
          <div>All Question related to quiz is show below</div>

          <div>
            <DragSortingTable
              id={params.id}
              tableData={questionList?.data?.questions}
              callBack={() => {
                callGetQuizQuestionList();
              }}
            />
          </div>
        </div>
      </div>
      <>
        {/* // Model Codding Start */}
        <AddQuestionModel
          visible={visible}
          setVisible={setVisible}
          defaultCategory={defaultCategory}
          id={params.id}
          callBack={() => {
            callGetQuizQuestionList();
          }}
        />
        ;{/* //Model Codding End */}
      </>
      {/* Edit Model Codding Start  */}
      <>
        <Modal
          title="Edit Quiz"
          centered
          footer={null}
          visible={editModelVisible}
          onCancel={() => setEditModelVisible(false)}
          width={1000}
        >
          <AddEditQuiz
            details={quizDetail}
            type="update"
            onSuccess={() => refreshAndClose({ callbackAction: "update" })}
          />
        </Modal>
      </>
      {/* Edit Model Codding End  */}
      {/* Add Comprehension Question to Quiz Model Codding Start  */}
      <>
        <Modal
          title="Add Comprehension Question to Quiz"
          centered
          footer={null}
          visible={comprehensionModelVisible}
          onCancel={() => refreshAndClose({ callbackAction: "cancel" })}
          width={1000}
        >
          <AddComprehensionQuestionToQuiz
            id={params.id}
            callBack={() =>
              refreshAndClose({ callbackAction: "QuestionToQuiz" })
            }
          />
        </Modal>
      </>
      {/* Add Comprehension Question to Quiz Model Codding  End  */}
    </div>
  );
};

export default AddQuestionToQuiz;
