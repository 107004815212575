import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Select, Upload, Button } from "antd";
import { getCategoryListForDropDownMenu } from "../../services/categoryServices";
import { UploadOutlined } from "@ant-design/icons";
const EditCategoryComponent = ({ visible, handleOk, data, handleCancel }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [list, setList] = useState();
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [nameVal, setNameVal] = useState(data?.name);
  const [descriptionVal, setDescriptionVal] = useState(data?.description);
  const [uploadImageFlag, setUploadImageFlag] = useState(false);
  const [idVal, setIdVal] = useState(data?.id);
  const [language, setLanguage] = useState(data?.language);
  const [form] = Form.useForm();
  const [file, setFile] = useState([]);
  const { TextArea } = Input;

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible]);

  useEffect(() => {
    if (
      nameVal !== data?.name ||
      descriptionVal !== data?.description ||
      idVal !== data?.id ||
      uploadImageFlag ||
      language !== data?.language
    ) {
      setIsFormChanged(true);
      // } else if (decription) {
    } else {
      setIsFormChanged(false);
    }
  }, [nameVal, descriptionVal, idVal, uploadImageFlag, language]);

  useEffect(() => {
    callGetCategoryList();
  }, [language]);

  const callGetCategoryList = async () => {
    const result = await getCategoryListForDropDownMenu({
      query: { language },
    });
    setList(result.data);
  };

  const handleChange = (value) => {
    setIdVal(value);
  };
  const handleChangeLanguage = (value) => {
    setLanguage(value);
  };
  function beforeUpload(file) {
    return false;
  }
  const onChangeFile = ({ fileList: newFileList }) => {
    setFile(newFileList?.[0].originFileObj);
  };

  const handelFinish = (value) => {
    var formData = new FormData();
    formData.append("file", file);
    if (value?.name) formData.append("name", value?.name);
    if (value?.description) formData.append("description", value?.description);
    if (value?.parent) formData.append("parent", value?.parent);
    if (value?.language) formData.append("language", value?.language);
    handleOk(formData);
  };

  return (
    <Modal
      title="Update Category"
      visible={visible}
      onOk={() => form.submit()}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      okText="Update"
      okButtonProps={{ disabled: !isFormChanged }}
    >
      <Form form={form} name="EditUserData" onFinish={handelFinish}>
        <Form.Item
          label="Select Language"
          name="language"
          initialValue={language ? language : "Punjabi"}
        >
          <Select
            style={{
              width: 120,
            }}
            onChange={handleChangeLanguage}
          >
            <Select.Option value="Punjabi">Punjabi </Select.Option>
            <Select.Option value="English">English </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="name"
          label="Category Name"
          initialValue={data?.name}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input value={nameVal} onChange={(e) => setNameVal(e.target.value)} />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          initialValue={data?.description}
        >
          <TextArea
            rows={4}
            onChange={(e) => setDescriptionVal(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          label="Parent Category"
          name="parent"
          initialValue={data?.parent?.id}
        >
          <Select
            showSearch
            placeholder="Change Parent"
            optionFilterProp="children"
            // onChange={onChange}
            // onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={handleChange}
          >
            {list &&
              list?.map((option) => {
                if (option._id === data.id) return null;
                return (
                  <Select.Option
                    key={option?._id}
                    value={option?._id}
                    // onSelect={(option) => setIdVal(option?._id)}
                  >
                    {option?.name}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item name="files">
          <Upload
            accept="image/png, image/jpeg"
            onChange={onChangeFile}
            beforeUpload={beforeUpload}
            fileList={[]}
          >
            <Button
              icon={<UploadOutlined />}
              onClick={() => setUploadImageFlag(true)}
            >
              Click to Upload
            </Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditCategoryComponent;

// //working copy of update
// import React, { useEffect, useState } from "react";
// import { Modal, Form, Input, Select } from "antd";
// import { getCategoryListForDropDownMenu } from "../../services/categoryServices";

// const EditCategoryComponent = ({ visible, handleOk, data, handleCancel }) => {
//   const [confirmLoading, setConfirmLoading] = useState(false);
//   const [list, setList] = useState();
//   const [isFormChanged, setIsFormChanged] = useState(false);
//   const [nameVal, setNameVal] = useState(data.name);
//   const [descriptionVal, setDescriptionVal] = useState(data.description);
//   const [idVal, setIdVal] = useState(data.id);

//   const [form] = Form.useForm();
//   const { TextArea } = Input;

//   useEffect(() => {
//     if (!visible) {
//       form.resetFields();
//     }
//   }, [visible]);

//   useEffect(() => {
//     if (
//       nameVal !== data.name ||
//       descriptionVal !== data.description ||
//       idVal !== data.id
//     ) {
//       setIsFormChanged(true);
//       // } else if (decription) {
//     } else {
//       setIsFormChanged(false);
//     }
//   }, [nameVal, descriptionVal, idVal]);

//   useEffect(() => {
//     callGetCategoryList();
//   }, []);

//   const callGetCategoryList = async () => {
//     const result = await getCategoryListForDropDownMenu();
//     setList(result.data);
//   };

//   const handleChange = (value) => {
//     setIdVal(value);
//   };

//   return (
//     <Modal
//       title="Update Category"
//       visible={visible}
//       onOk={() => form.submit()}
//       confirmLoading={confirmLoading}
//       onCancel={handleCancel}
//       okText="Update"
//       okButtonProps={{ disabled: !isFormChanged }}
//     >
//       <Form
//         form={form}
//         name="EditUserData"
//         onFinish={(values) => handleOk(values)}
//       >
//         <Form.Item
//           name="name"
//           label="Category Name"
//           initialValue={data?.name}
//           rules={[
//             {
//               required: true,
//             },
//           ]}
//         >
//           <Input value={nameVal} onChange={(e) => setNameVal(e.target.value)} />
//         </Form.Item>
//         <Form.Item
//           name="description"
//           label="Description"
//           initialValue={data?.description}
//         >
//           <TextArea
//             rows={4}
//             onChange={(e) => setDescriptionVal(e.target.value)}
//           />
//         </Form.Item>
//         <Form.Item
//           label="Parent Category"
//           name="parent"
//           initialValue={data?.parent?.id}
//         >
//           <Select
//             showSearch
//             placeholder="Change Parent"
//             optionFilterProp="children"
//             // onChange={onChange}
//             // onSearch={onSearch}
//             filterOption={(input, option) =>
//               option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
//             }
//             onChange={handleChange}
//           >
//             {list &&
//               list?.map((option) => {
//                 if (option._id === data.id) return null;
//                 return (
//                   <Select.Option
//                     key={option?._id}
//                     value={option?._id}
//                     // onSelect={(option) => setIdVal(option?._id)}
//                   >
//                     {option?.name}
//                   </Select.Option>
//                 );
//               })}
//           </Select>
//         </Form.Item>
//       </Form>
//     </Modal>
//   );
// };

// export default EditCategoryComponent;
