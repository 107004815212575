import callApi from "../utils/callApi";

export const postQuestion = async (payload) => {
  console.log("payload in api", payload);
  const result = await callApi({
    method: "post",
    url: "/api/admin/question",
    data: payload,
  });
  // call api structure what is demanded is given below callApi(method, url, data, headers)
  return result;
};

export const postQuestionImage = async (payload) => {
  console.log("payload in api", payload);
  const result = await callApi({
    method: "post",
    url: "/api/admin/question/uploadimagequestion",
    data: payload,
  });
  // call api structure what is demanded is given below callApi(method, url, data, headers)
  return result;
};

export const getQuestionList = async ({ query }) => {
  const result = await callApi({
    method: "get",
    url: "/api/admin/question",
    query,
  });
  // call api structure what is demanded is given below callApi(method, url, data, headers)
  return result;
};

export const deleteQuestion = async (id) => {
  const result = await callApi({
    method: "delete",
    url: `/api/admin/question/${id}`,
  });
  // call api structure what is demanded is given below callApi(method, url, data, headers)
  return result;
};

export const getSingleQuestion = async (id) => {
  const result = await callApi({
    method: "get",
    url: `/api/admin/question/${id}`,
  });
  // call api structure what is demanded is given below callApi(method, url, data, headers)
  return result;
};

export const updateQuestion = async ({ id, customPayload }) => {
  const result = await callApi({
    method: "put",
    url: `/api/admin/question/${id}`,
    data: customPayload,
  });
  // call api structure what is demanded is given below callApi(method, url, data, headers)
  return result;
};

export const getComprehensionQuestionList = async ({ id, query }) => {
  console.log("query in api", query);
  const result = await callApi({
    method: "get",
    url: `/api/admin/question/comprehensionquestion/${id}`,
    query,
  });
  // call api structure what is demanded is given below callApi(method, url, data, headers)
  return result;
};
