import React from "react";
import DragSortingTable from "../../../components/DraggableTable";
const HomePage = () => {
  return (
    <div>
      <div>HomePage</div>
      <div> {/* <DragSortingTable tableData="Hello" /> */}</div>
    </div>
  );
};

export default HomePage;
