import React, { useEffect } from "react";
import styles from "./styles.module.css";
const Rough = () => {
  return (
    <div className={styles.main}>
      <div className={styles.section}>A</div>
      <div className={styles.section}>
        B<div>Total Number of Categories</div>
        <div>Total Number of Questions </div>
        <div>
          Total Number of Users
          <p>Number of Active Users:</p>
        </div>
        <div>Total Number of Quizzes</div>
      </div>
      <div className={styles.section}>C</div>
      <div className={styles.section}>D</div>
    </div>
  );
};

export default Rough;
