import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Form, Input, Button, notification } from "antd";
import "../components/css/index.css";
import loginSvg from "../assets/svg/login.svg";
import { Link } from "react-router-dom";
import { login } from "../redux/actions";

const Login = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const onFinish = async (values) => {
    const { email, password } = values;
    //dispatch goes login action
    dispatch(login(email, password));
  };

  const auth = useSelector((state) => state.userLogin);
  const { loading: loginLoading, error, userInfo } = auth;
  console.log("login error: ", error);
  //destructure aliasing loading: loginLoading

  useEffect(() => {
    if (userInfo) {
      navigate("/", { replace: true });
    }
  }, [navigate, userInfo]);

  console.log("userLogin state: ", auth);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="main">
      <div className="section">
        <div
          className="column displayNone"
          style={{ backgroundColor: "#F7FAFC" }}
          // button color of ant desing in hax code is #40A9FF
        >
          <div>
            <img src={loginSvg} alt="Login" className="imageSvg" />
          </div>
          <div className="fontBlackBold">
            Get... Set.. Ready.
            <br /> Quiz App
          </div>
        </div>
        <div className="column">
          <div className="loginRightContainer">
            <div>
              <img src={loginSvg} alt="Login" className="imageSvgSmall" />
            </div>
            <div className="title">Welcome Back!</div>
            <div className="subtitle">
              Enter your{" "}
              <span style={{ color: "#585858", fontWeight: "bold" }}>
                email address{" "}
              </span>
              and
              <span style={{ color: "#585858", fontWeight: "bold" }}>
                {" "}
                password{" "}
              </span>
              to log in.
            </div>
            <div>
              <Form
                name="basic"
                layout="vertical"
                requiredMark={false}
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  label="Email"
                  name="email"
                  className="lable"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                    {
                      type: "email",
                      message: "Please Enter a Valid email",
                    },
                  ]}
                  hasFeedback
                  validateStatus={error && error ? "error" : ""}
                >
                  <Input
                    size="large"
                    style={{ borderRadius: "5px" }}
                    // id="error"
                  />
                </Form.Item>

                <Form.Item
                  label={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <div>Password</div>{" "}
                      <div style={{ color: "#40A9FF" }}>
                        {" "}
                        {/* <Link to="/forgetpassword">Forgot your password?</Link> */}
                      </div>
                    </div>
                  }
                  name="password"
                  className="customLabel"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                  validateStatus={error && error ? "error" : ""}
                  //validateStatus is used to show error or other simble inside a input box
                  help={error && error ? error : " "}
                >
                  <Input.Password
                    size="large"
                    style={{ borderRadius: "5px" }}
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    style={{
                      width: "100%",
                      height: "40px",
                      borderRadius: "5px",
                    }}
                    loading={loginLoading}
                    type="primary"
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
            <div>
              If you don't have account.{" "}
              <strong>
                <Link to="/register">Click here to create..</Link>
              </strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
