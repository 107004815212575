import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import QuizList from "../QuizList";
import styles from "./styles.module.css";
import { Button, Modal } from "antd";
import AddEditQuiz from "../Components/AddEditQuiz";
const QuizInCategory = () => {
  const location = useLocation();
  const [addQuizModelVisible, setAddQuizModelVisible] = useState(false);
  const { from } = location.state;

  console.log("link value", from);
  const dateConverter = (date) => {
    return dayjs(date).format("DD-MM-YYYY h:mm");
  };

  const navigate = useNavigate();
  return (
    <div className={styles.main}>
      <div
        style={{
          flex: 1,
          marginTop: 5,
        }}
      >
        <div
          style={{
            marginLeft: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "red",
            fontSize: 16,
            fontWeight: "600",
          }}
        >
          List of Quiz in Category
        </div>
        <div style={{ marginLeft: 10 }}>
          <div>
            Category Name:<b> {from?.singleCategory?.name} </b>
          </div>
          {/* {console.log("value in state", singleCategory)} */}
          <div>
            Category Descripton: <b> {from?.singleCategory?.description}</b>
          </div>
          <div>
            Language: <b> {from?.singleCategory?.language}</b>
          </div>
          <div>
            Parent: <b> {from?.singleCategory?.parent?.name}</b>
          </div>
          <div>
            Created By: <b>{from?.singleCategory?.createdby}</b>
          </div>
          <div>
            Created At:<b> {dateConverter(from?.singleCategory?.createdAt)}</b>{" "}
          </div>
          <Button
            onClick={() => navigate(-1)}
            className={styles.button}
            type="primary"
          >
            Go Back
          </Button>
          <Button
            className={styles.button}
            type="primary"
            onClick={() => setAddQuizModelVisible(true)}
          >
            Add Quiz
          </Button>
        </div>
        <div>
          <QuizList
            category={from?.id}
            language={from?.singleCategory?.language}
            showCustomId={true}
          />
        </div>
        {/* Add New Quiz Modal Start */}
        <div>
          <Modal
            visible={addQuizModelVisible}
            title="Add Quiz in Category"
            footer={null}
            destroyOnClose={true}
            width={1000}
            onCancel={() => {
              setAddQuizModelVisible(false);
            }}
          >
            <AddEditQuiz
              callBack={() => {
                setAddQuizModelVisible(false);
                window.location.reload(false);
              }}
              categoryFromModel={from?.singleCategory}
              type="create"
            />
          </Modal>
        </div>
        {/* Add New Quiz Modal End */}
      </div>
    </div>
  );
};
export default QuizInCategory;
