import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  notification,
  message,
  Upload,
} from "antd";
import styles from "./styles.module.css";
import { UploadOutlined } from "@ant-design/icons";

import {
  getCategoryListForDropDownMenu,
  postCategory,
} from "../../../services/categoryServices";
import {
  categoryColorScheme,
  sortDropDownMenu,
} from "../../../components/SortAndColorDropDownMenu";

const AddNewMainCategory = ({ categoryFromModel, callBack }) => {
  const user = localStorage.getItem("userInfo");
  //we get user detail from localstorage and used it to send in payload as created by as user id
  var formData = new FormData();

  const userInfoObject = JSON.parse(user);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState([]);

  const [data, setData] = useState();
  const languageOptions = ["Punjabi", "English"];
  const [language, setLanguage] = useState(
    categoryFromModel?.language && categoryFromModel?.language
  );

  const [form] = Form.useForm();
  //instance of form i created here, instance must be a array always
  useEffect(() => {
    callGetCategoryList();
  }, [language]);

  const callGetCategoryList = async () => {
    const result = await getCategoryListForDropDownMenu({
      query: { language },
    });
    const sortedData = await result?.data?.sort(sortDropDownMenu);
    setData(sortedData);
  };

  console.log("userinfo", userInfoObject);
  const onFinish = async (values) => {
    console.log("values", values);
    try {
      setLoading(true);
      formData.append("file", file);
      if (values?.name) formData.append("name", values?.name);
      if (values?.language) formData.append("language", values?.language);
      if (values?.description)
        formData.append("description", values?.description);
      if (values?.parent) formData.append("parent", values?.parent);
      formData.append("createdby", userInfoObject?._id);
      console.log("From data", formData);
      const response = await postCategory(formData);

      if (response.message !== "success") {
        throw new Error(
          "Something went wrong. Please try again or contact administrator"
        );
      }
      message.success("New category is added in a Database");
      form.resetFields();
      callGetCategoryList();
      setLoading(false);
      categoryFromModel && callBack();
    } catch (error) {
      notification.error({
        message: `${error?.status}: ${error?.statusText}`,
        description: error?.data?.message,
      });
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  function handleChange(value) {
    console.log(`selected ${value}`);
    setLanguage(value);
  }

  const onChangeFile = ({ fileList: newFileList }) => {
    setFile(newFileList?.[0].originFileObj);
  };

  function beforeUpload(file) {
    return false;
  }

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div>Add New Main Category</div>
        <div className={styles.form}>
          <Form
            form={form}
            name="basic"
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Select Language"
              name="language"
              initialValue={
                categoryFromModel?.language && categoryFromModel?.language
              }
            >
              <Select
                style={{
                  width: 120,
                }}
                onChange={handleChange}
              >
                {languageOptions &&
                  languageOptions?.map((option, index) => (
                    <Select.Option key={index} value={option}>
                      {option}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Category Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input title of Category!",
                },
              ]}
            >
              <Input
                size="large"
                style={{ borderRadius: "5px" }}
                placeholder="Enter Title of Category"
              />
            </Form.Item>
            <Form.Item label="Description (Optional)" name="description">
              <Input
                size="large"
                style={{ borderRadius: "5px" }}
                placeholder="Enter Description of Category (Optional)"
              />
            </Form.Item>

            <Form.Item
              label="Parent Category (Optional)"
              name="parent"
              initialValue={categoryFromModel?.id && categoryFromModel?.id}
            >
              <Select placeholder="Select a Category">
                {data &&
                  data?.map((option) => (
                    <Select.Option key={option?._id} value={option?._id}>
                      {categoryColorScheme({ name: option?.name, language })}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item name="files">
              <Upload
                accept="image/png, image/jpeg"
                onChange={onChangeFile}
                beforeUpload={beforeUpload}
                fileList={[]}
              >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 0,
                span: 24,
              }}
            >
              <Button loading={loading} type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AddNewMainCategory;
