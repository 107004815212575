import React, { useState, useEffect } from "react";
import {
  deleteComprehension,
  getComprehensionList,
} from "../../../services/comprehensionServices";
import {
  Button,
  notification,
  Popconfirm,
  Table,
  Spin,
  Space,
  Modal,
  message,
  Pagination,
  Tooltip,
} from "antd";
import { Link } from "react-router-dom";
import AddEditComprehension from "../Components/AddEditComprehension";

import ViewComprehensionAndQuestions from "../Components/ViewComprehensionAndQuestions";
import styles from "./styles.module.css";
import AddNewQuestion from "../../Question/AddNewQuestion";

const ComprehensionList = () => {
  const [loading, setLoading] = useState();
  const [comprehensionList, setComprehensionList] = useState({
    data: [],
    totalRecords: 0,
  });
  const [buttonLoading, setButtonLoading] = useState(false);
  const [language, setLanguage] = useState();
  const [category, setCategory] = useState();

  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(10);
  const [id, setId] = useState();

  useEffect(() => {
    callGetComprehensionList();
  }, [start, limit]);

  // useEffect(() => {
  //   if (id) {
  //     setViewModelVisible(true);
  //     console.log("id in calling ", id);
  //   }
  // }, [id]);
  //Edit Model Declaration Codding Start
  const [editModelVisible, setEditModelVisible] = useState(false);
  const [addQuestionVisible, setAddQuestionVisible] = useState(false);
  const refreshAndClose = () => {
    setEditModelVisible(false);
    callGetComprehensionList();
  };

  const callGetComprehensionList = async () => {
    try {
      setLoading(true);
      const result = await getComprehensionList({
        query: { start, limit },
      });
      setLoading(false);
      setComprehensionList(result);
      console.log(comprehensionList);
      console.log("Result", result);
    } catch (error) {
      console.log("error: ", error);
      notification.error({
        message: `${error?.status}: ${error?.statusText}`,
        description: error?.data?.message,
      });
      // setLoading(false);
    }
  };

  const callDeleteComprehension = async (id) => {
    try {
      console.log("ID to delete", id);
      setLoading(true);
      const result = await deleteComprehension(id);
      if (result?.message === "success") {
        message.success("Record Deleted Successfully");
      } else message.warning("Something went Wrong");
      setLoading(false);
      callGetComprehensionList();
    } catch (error) {
      notification.error({
        message: `${error?.status}: ${error?.statusText}`,
        description: error?.data?.message,
      });
    }
  };

  const columns = [
    {
      title: <div className={styles.heading}>Id </div>,
      key: "index",
      render: (text, record, index) => (
        <div className={styles.heading}>{index + 1}</div>
      ),
      width: 55,
    },
    {
      title: "Title",
      dataIndex: "slug",
      key: "slug",
    },
    {
      title: "Comprehension",
      dataIndex: "comprehension",
      key: "_id",

      render: (value, record) => <div>{value + ". . . ."}</div>,
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "_id",
      render: (value, record) => record?.category?.name,
    },
    {
      title: "Created by",
      dataIndex: "createdBy",
      key: "_id",
      render: (value, record) => record?.createdBy?.name,
    },
    {
      title: "Total Question",
      dataIndex: "questionCount",
      key: "_id",
      render: (value, record) => record?.questionCount,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Tooltip
            title="View Comprehension and related question."
            placement="topLeft"
          >
            <Button
              onClick={() => {
                openViewQuestionModal(record?._id);
              }}
            >
              View
            </Button>
          </Tooltip>
          <Popconfirm
            title="This will delete Comprehension, Questions added in it and Questions related to some quizes. Be carefull while deleting this"
            s
            onConfirm={() => callDeleteComprehension(record._id)}
            okText="Confirm"
            cancelText="Cancel"
            placement="leftTop"
            okButtonProps={{
              danger: true,
            }}
          >
            <Tooltip
              title="This will delete Comprehension, Questions added in it and Questions related to some quizes. Be carefull while deleting this"
              color="red"
            >
              <Button type="primary" danger>
                Delete
              </Button>
            </Tooltip>
          </Popconfirm>
          {/* <Link to="/addcomprehension">
            <Button type="primary">Add </Button>
          </Link> */}
          <Tooltip title="Edit Comprehension.">
            <Button
              onClick={() => {
                openUpdateQuestionModal(record?._id);
              }}
              type="primary"
            >
              Edit
            </Button>
          </Tooltip>
          <Tooltip
            placement="topRight"
            title="Add New Question in Comprehension"
          >
            <Button
              type="primary"
              loading={buttonLoading}
              onClick={() => openAddQuestionModal(record)}
            >
              Add Question
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ];
  // Comprehension View Modal Declaration Start
  const [viewModelVisible, setViewModelVisible] = useState(false);

  // Comprehension View Modal Declaration End
  //Add Question to Comprehension modal declaration start

  const openAddQuestionModal = (record) => {
    setId(record?._id);
    setCategory(record?.category?._id);
    setLanguage(record?.category?.language);
    setTimeout(() => {
      setAddQuestionVisible(true);
    }, 200);
  };
  const openViewQuestionModal = (id) => {
    setId(id);
    setViewModelVisible(true);
  };

  useEffect(() => console.log("comp id: ", id), [id]);
  //Add Question to Comprehension modal declaration end
  //Update Question to Comprehension modal declaration start
  const [updateId, setUpdateId] = useState();
  const openUpdateQuestionModal = (id) => {
    // setButtonLoading(true);
    setUpdateId(id);
  };

  useEffect(() => {
    if (updateId) {
      // setButtonLoading(false);
      setEditModelVisible(true);
    }
  }, [updateId]);

  //Update Question to Comprehension modal declaration end
  const onShowSizeChange = (current, pageSize) => {
    setLimit(pageSize);
    // console.log(current, pageSize);
  };
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div>Comprehension Table is given below</div>
        <Spin spinning={loading} size="large">
          <div>
            <>
              <Table
                columns={columns}
                dataSource={comprehensionList?.data}
                pagination={false}
                rowKey={(record) => record?._id}
              />
              <Pagination
                total={comprehensionList?.totalRecords}
                showTotal={(total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`
                }
                showSizeChanger
                pageSize={limit}
                onShowSizeChange={onShowSizeChange}
                current={start / limit + 1}
                onChange={(page, pageSize) => {
                  setStart((page - 1) * limit);
                }}
              />
            </>
          </div>
        </Spin>
        <div>
          {/* Edit Model Codding Start  */}
          <>
            <Modal
              title="Edit Comprehension"
              centered
              footer={null}
              visible={editModelVisible}
              onCancel={() => {
                setEditModelVisible(false);
                setUpdateId("");
              }}
              destroyOnClose={true}
              width={1000}
            >
              <AddEditComprehension
                id={updateId}
                type="update"
                onSuccess={() => refreshAndClose()}
                callBack={() => setEditModelVisible(false)}
              />
            </Modal>
          </>
          {/* Edit Model Codding End  */}
        </div>
        <div>
          {/* Add Question to Comprehension Model Codding Start  */}
          <>
            <Modal
              title="Add Question to Comprehension "
              centered
              footer={null}
              visible={addQuestionVisible}
              destroyOnClose={true}
              onCancel={() => {
                setAddQuestionVisible(false);
                callGetComprehensionList();
              }}
              width={900}
            >
              <AddNewQuestion
                type="addQuestionToComprehension"
                comprehensionId={id} //this is the comprehension id
                categoryFromModel={category}
                languageFromModel={language}
                callBack={() => {
                  setAddQuestionVisible(false);
                  callGetComprehensionList();
                }}
              />
            </Modal>
          </>
          {/* Add Question to Comprehension  Model Codding End  */}
        </div>
        {/* Comprehension View Model Start */}
        <div>
          <>
            <Modal
              visible={viewModelVisible}
              title="View Comprehension"
              destroyOnClose={true}
              // onOk={()=>handleOk()}
              onCancel={() => {
                // setId("");
                setTimeout(() => {
                  setViewModelVisible(false);
                }, 100);
              }}
              width={"90%"}
              footer={[
                <Button
                  key="back"
                  onClick={() => {
                    setId("");
                    setTimeout(() => {
                      setViewModelVisible(false);
                      callGetComprehensionList();
                    }, 100);
                    //setTimeout is just a trick to give time to setId state to set. this is not a perfect solution
                  }}
                >
                  Return
                </Button>,
              ]}
            >
              <ViewComprehensionAndQuestions id={id} />
            </Modal>
          </>
        </div>
        {/* Comprehension View Model Start */}
      </div>
    </div>
  );
};

export default ComprehensionList;
