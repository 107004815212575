import React, { useEffect, useState } from "react";

import {
  notification,
  Table,
  Spin,
  Popconfirm,
  Pagination,
  Button,
  message,
  Space,
  Modal,
  Tooltip,
} from "antd";
import {
  deleteQuestion,
  getQuestionList,
} from "../../../services/questionServices";
import styles from "./styles.module.css";
import AddUpdateQuestionComponent from "../Component/AddUpdateQuestionComponent";

const QuestionList = () => {
  const [questionList, setQuestionList] = useState({
    data: [],
    totalRecords: 0,
  });
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(10);
  const [visible, setVisible] = useState(false);

  const [recordId, setRecordId] = useState("");

  useEffect(() => {
    getQuestionListFunction();
  }, [start, limit]);

  const getQuestionListFunction = async () => {
    try {
      setLoading(true);
      const result = await getQuestionList({
        query: { start, limit, type: "boolean" },
      });
      setLoading(false);
      setQuestionList(result);
      // console.log(result);
    } catch (error) {
      console.log("error: ", error);
      notification.error({
        message: `${error?.status}: ${error?.statusText}`,
        description: error?.data?.message,
      });
      // setLoading(false);
    }
  };

  const deleteQuestionFunction = async (id) => {
    try {
      console.log("ID to delete", id);
      setLoading(true);
      const result = await deleteQuestion(id);
      setLoading(false);
      if (result?.message === "success") {
        message.success("Question Deleted From Database");
      } else message.warning("Something Went Wrong");

      getQuestionListFunction();
    } catch (error) {
      notification.error({
        message: `${error?.status}: ${error?.statusText}`,
        description: error?.data?.message,
      });
    }
  };

  const columns = [
    {
      title: <div className={styles.heading}>Id </div>,
      key: "index",
      render: (text, record, index) => (
        <div className={styles.heading}>{index + 1}</div>
      ),
      width: 55,
    },
    {
      title: <div className={styles.heading}>TITLE </div>,
      dataIndex: "title",
      key: "title",
      render: (value, record) =>
        record?.title?.type === "text" ? (
          record?.title?.text
        ) : (
          <img
            src={record?.title?.text}
            alt="Login"
            style={{ height: "80px", width: "120px" }}
          />
        ),
    },
    {
      title: <div className={styles.heading}>Category </div>,
      dataIndex: "category",
      key: "_id",
      render: (value, record) => record?.category?.name,
    },
    {
      title: <div className={styles.heading}>Question Type</div>,
      dataIndex: "type",
      key: "type",
    },
    {
      title: <div className={styles.heading}>Option A</div>,
      dataIndex: "option1",
      key: "option1",
      render: (value, record) =>
        record?.option1?.type === "text" ? (
          record?.option1?.text
        ) : (
          <img
            src={record?.option1?.text}
            alt="Login"
            style={{ height: "80px", width: "120px" }}
          />
        ),
    },
    {
      title: <div className={styles.heading}>Option B</div>,
      dataIndex: "option2",
      key: "option2",
      render: (value, record) =>
        record?.option2?.type === "text" ? (
          record?.option2?.text
        ) : (
          <img
            src={record?.option2?.text}
            alt="Login"
            style={{ height: "80px", width: "120px" }}
          />
        ),
    },
    {
      title: <div className={styles.heading}>Option C</div>,
      dataIndex: "option3",
      key: "option3",
      render: (value, record) =>
        record?.option3?.type === "text" ? (
          record?.option3?.text ? (
            record?.option3?.text
          ) : null
        ) : record?.option3?.text ? (
          <img
            src={record?.option3?.text}
            alt="Login"
            style={{ height: "80px", width: "120px" }}
          />
        ) : null,
    },
    {
      title: <div className={styles.heading}>Option D</div>,
      dataIndex: "option4",
      key: "option4",
      render: (value, record) =>
        record?.option4?.type === "text" ? (
          record?.option4?.text ? (
            record?.option4?.text
          ) : null
        ) : record?.option4?.text ? (
          <img
            src={record?.option4?.text}
            alt="Login"
            style={{ height: "80px", width: "120px" }}
          />
        ) : null,
    },
    {
      title: <div className={styles.heading}>Answer</div>,
      dataIndex: "answer",
      key: "answer",
      render: (value, record) => {
        return <div dangerouslySetInnerHTML={{ __html: value }} />;
      },
      width: 100,
    },

    {
      title: (
        <div style={{ color: "red", fontWeight: 800, letterSpacing: "2px" }}>
          Action
        </div>
      ),
      key: "action",

      render: (text, record) => (
        <Space size="middle">
          <Popconfirm
            title="Are you sure to delete this task?"
            onConfirm={() => deleteQuestionFunction(record._id)}
            // onCancel={cancel}
            // getPopupContainer={(node) => node.parentNode}
            okText="Confirm"
            cancelText="Cancel"
            placement="leftTop"
            okButtonProps={{
              danger: true,
            }}
          >
            <Tooltip
              placement="topLeft"
              title="This will delete question and reference from all quizes. Be careful while using this"
            >
              <Button type="primary" danger>
                Delete
              </Button>
            </Tooltip>
          </Popconfirm>
          <Button
            type="primary"
            onClick={() => {
              setRecordId(record?._id);
              setVisible(true);
            }}
          >
            Edit
          </Button>
        </Space>
      ),
    },
  ];
  // Edit modal Declaration Start Here

  // Edit modal Declaration End Here
  const onShowSizeChange = (current, pageSize) => {
    setLimit(pageSize);
    // console.log(current, pageSize);
  };
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <Spin spinning={loading} size="large">
          <div>
            <b>Question list</b>
          </div>
          <div>
            <>
              <Table
                columns={columns}
                dataSource={questionList?.data}
                pagination={false}
                // scroll={{ y: 500 }}
                rowKey={(record) => record?._id}
              />
              <Pagination
                total={questionList?.totalRecords}
                showTotal={(total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`
                }
                showSizeChanger
                pageSize={limit}
                onShowSizeChange={onShowSizeChange}
                current={start / limit + 1}
                onChange={(page, pageSize) => {
                  setStart((page - 1) * limit);
                }}
              />
            </>
          </div>
        </Spin>
      </div>
      {/* Edit Modal Start Here */}
      <div>
        <Modal
          visible={visible}
          title="Update Question"
          closable={false}
          footer={null}
          destroyOnClose={true}
          width={1000}
        >
          {recordId !== "" && (
            <AddUpdateQuestionComponent
              id={recordId}
              callBack={() => {
                setVisible(false);
                setRecordId("");
                getQuestionListFunction();
              }}
            />
          )}
        </Modal>
      </div>
      {/* Edit Modal End Here */}
    </div>
  );
};

export default QuestionList;
