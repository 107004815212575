export const sortDropDownMenu = (a, b) => {
  // converting to uppercase to have case-insensitive comparison
  const name1 = a.name.toUpperCase();
  const name2 = b.name.toUpperCase();

  let comparison = 0;

  if (name1 > name2) {
    comparison = 1;
  } else if (name1 < name2) {
    comparison = -1;
  }
  return comparison;
};

export const categoryColorScheme = ({ name, language }) => {
  let result;
  language === "English" && (result = name.indexOf("PSTET 1"));
  language === "Punjabi" && (result = name.indexOf("ਪੀ.ਐੱਸ.ਟੀ.ਈ.ਟੀ. 1"));

  let color = "white";
  let textColor = "black";
  if (result >= 0) {
    color = "blue";
    textColor = "white";
  }
  return (
    <div
      style={{
        backgroundColor: color,
        color: textColor,
        fontWeight: 600,
        padding: 3,
      }}
    >
      {name}
    </div>
  );
};
