import React from "react";

import AddEditComprehension from "../Components/AddEditComprehension";
const AddComprehension = () => {
  return (
    <div>
      <AddEditComprehension type={"create"} />
    </div>
  );
};

export default AddComprehension;
