import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";

const SecurityLayout = () => {
  let location = useLocation();
  const accessToken = localStorage.getItem("accessToken");

  if (!accessToken && location.pathname !== "/auth/login") {
    // if accessToken is null mean no one is login and location is not equal to login page then return to login page. no unauthorize access is given.
    return <Navigate to="/auth/login" />;
  }

  return <Outlet />;
  // <Outlet /> is a child elements of SecurityLayout. it will render pages/child element according to path
};

export default SecurityLayout;
