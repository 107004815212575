import React from "react";

import styles from "./styles.module.css";

const TermAndConditions = () => {
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <span className={styles.mainTitle}>
          Quiz App – Terms &amp;Conditions
          <br />
        </span>
        <p className={styles.paragraph}>
          This Terms &amp;Conditions is applicable to your access to our website
          – Quiz App and embarks on the way Quiz App utilizes and safeguards any
          detail that you i.e. visitors/users provide Quiz App .<br />
          Quiz App is devoted to determine the safety of your privacy. You will
          be asked to provide specific details by which we can identify you when
          you are accessing our site, then we can assure you that it will be
          specifically utilized as per this privacy policy.
          <br />
          Quiz App holds rights to change this privacy policy at any time
          without any prior notice to you (users). To stay updated about the
          privacy policy or new changes in the policy, you can check the page
          time to time.
          <br />
          What Information Quiz App Collects –Our website may collection
          information/data as cookies, name, contact information and email
          addresses. How we use the information we collect – We need this
          data/information to learn your requirements accurately and offer you a
          quality service with adequate solution of your interest, and for
          following causes:
          <br />
          Record keeping for internal use.
          <br />
          We may likely utilize this data to respond your queries, emails or
          web-based requests, to furnish and administer surveys, to measure the
          fees, enhance products/ services Quiz App offers.
          <br />
          Quiz App may at times send emails relating various promotional
          activities about new launches, certain offers or any other kind of
          information you may be interested in, using your email ID provided by
          you.
          <br />
          The collected information may also be used in customization of our
          site as per your interests.
          <br />
          <strong>
            <br />
            User Security  <br />
          </strong>
          Quiz App generally supports industry norms to provide security to the
          user information shared to us during the transmission and after
          receiving it. However, no medium of information transmission on the
          web, or mobile device, or even electronic storage is absolutely safe.
          <br />
          Regardless of our efforts, Quiz App does not take guarantee of the
          information security that you transit or transfer to us, or even
          from/to our online services or products.
          <br />
          <br />
          <strong>
            Use of Cookies
            <br />
          </strong>
          The cookie is a short file asking permission to put on the hard-drive
          of your computer. Once you give your agreement, this file gets added,
          and then cookie supports web traffic analysis or enables you learn
          when you check a specific site. With these cookies, web apps can
          easily respond you individually. The app can customize its functions
          as per your requirements, interests, and likes/dislikes by collecting
          and memorising data/information relating your preferences.
          <br />
          We utilize web traffic cookies log to discover which web pages are
          being frequently visited. It is a way to analyse web data of page
          traffic to enhance our site to design it to the needs of customers.
          The key use of this information is for statistical analysis and after
          that, the taken data will be removed from system. Wholly, cookies
          provide you a quality web experience, by helping us to see pages of
          your interest and make online experience more relevant. A website
          cookie does not provide us any kind of access to your information or
          computer devices in any way, except data you agree to share here. The
          acceptance of cookies is entirely up to you (users). A majority of
          browsers accept web cookies on their own; however, users often have
          power to modify the setting of their browsers.
          <br />
          <br />
          <strong>
            Other Websites Links
            <br />
          </strong>
          Our site may have links to any other site/sites of your interest. But,
          if you click on these links and leave our website, you need to be
          aware of that we have no control over the redirected site. So, you
          should not expect from us to take responsibility for your privacy or
          information protection from those sites. We advise you to practice
          caution and check out the privacy statement of that site
          appropriately.
          <br />
          ‍<br />‍
          <strong>
            Supervising Your Personal Data/Information  <br />
          </strong>
          We shall not distribute, sell or even lease your information to any
          third party until we get your approval or is requisite by the law.
          Quiz App may share your personal details if needed in the healthy
          belief that the action is required to:
          <br />
          Obey legal requirements and/or conform the legal process presented at
          Quiz App.
          <br />
          Safeguard and/or preserve the property or rights of Quiz App.
          <br />
          Secure the personal security of Quiz App members or personnel in
          adequate circumstances. We shall also release certain details in cases
          like if an attempted security breach in the site or to inform to law
          enforcement personnel about any activities that seem to be unlawful.
          <strong>
            <br />
          </strong>
          <br />
          <strong>
            Acceptance of Our T &amp;C
            <br />
          </strong>
          Using our site, you agree to the utilization, collection and
          disclosure of the information you given to us in a way described in
          our privacy policy and shows your consent to this policy. If you are
          not giving your agreement to this policy, kindly do not access the
          site.
        </p>
      </div>
    </div>
  );
};

export default TermAndConditions;
