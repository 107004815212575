import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import {
  Table,
  Button,
  Spin,
  Popconfirm,
  message,
  Tooltip,
  Space,
  Modal,
} from "antd";
import {
  deleteCategory,
  getSingleCategory,
  getSubCategoryList,
  setCategoryOnlineOffline,
  updateCategory,
} from "../../../services/categoryServices";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { Link, useNavigate } from "react-router-dom";
import EditCategoryComponent from "../../../components/EditCategoryComponent/EditCategoryComponent";
import UpdateCustomId from "../../../components/UpdateCustomId";

import AddNewMainCategory from "../addNewMainCategory/AddNewMainCategory";

const CategoryDetail = () => {
  const [categoryList, setCategoryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [detailLoading, setDetailLoading] = useState(false);
  const [singleCategory, setSingleCategory] = useState();
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [updateCustomIdModal, setupdateCustomIdModal] = useState(false);
  const [currentCustomId, setCurrentCustomId] = useState();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [addCategoryModelVisible, setAddCategoryModelVisible] = useState(false);

  const navigate = useNavigate();
  // 61df990b68802406cd2c7a88
  let params = useParams();
  //params contain current path id

  useEffect(() => {
    setCategorylistFunction();
    getSingleCategoryFunction();
  }, [params.id]);

  const setCategorylistFunction = async () => {
    setLoading(true);
    const result = await getSubCategoryList(params.id);
    setLoading(false);
    setCategoryList(result);
    // console.log(result);
  };

  const getSingleCategoryFunction = async () => {
    setDetailLoading(true);
    const res = await getSingleCategory(params.id);
    setSingleCategory(res.data);
    setDetailLoading(false);
  };

  const dateConverter = (date) => {
    return dayjs(date).format("DD-MM-YYYY h:mm");
  };

  const deleteRecord = async (id) => {
    setDeleteLoading(true);

    if (categoryList.length === 0) {
      // console.log("i can delete it now");
      const deleted = await deleteCategory(id);
      // console.log("Deleted", deleted);
      message.success("Record Deleted Successfully");
      setDeleteLoading(false);
      navigate(-1);
    } else {
      message.warning("Delete or Move Sub Categories First");
      setDeleteLoading(false);
    }
    // console.log("Delete or Move Sub Categories First");
  };
  // console.log("value in category list state", categoryList);

  const updateRecordFuntion = async (values) => {
    setDetailLoading(true);
    const id = params.id;
    const response = await updateCategory({ id, payload: values });

    if (response.message === "success") {
      message.success("Record Updated Successfully");
      getSingleCategoryFunction();
    } else {
      console.log("Something went wrong");
      message.error("Something went wrong");
    }
    setDetailLoading(false);
  };
  const callSetCategoryOnlineOffline = async (record) => {
    try {
      // setLoading(true);

      console.log("grant revoke record", record);
      const payload = {
        is_active: record?.is_active,
      };
      console.log("grant revoke payload", payload);
      const response = await setCategoryOnlineOffline({
        id: record?.id,
        payload,
      });
      if (response?.message === "success")
        message.success("Quiz Online Status Changed");
      else message.success("Something went Wrong");
      setCategorylistFunction();
    } catch (error) {
      Notification.error({
        message: `${error?.status}: ${error?.statusText}`,
        description: error?.data?.message,
      });
    }
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "customId",
      key: "customId",
      render: (value, record) => (
        <div
          style={{
            backgroundColor: "blue",
            textAlign: "center",
            cursor: "pointer",
            color: "white",
            borderRadius: 4,
            padding: 5,
            fontWeight: "700",
          }}
          onClick={() => {
            setCurrentCustomId(record);
            setupdateCustomIdModal(true);
          }}
        >
          {value}
        </div>
      ),
    },
    {
      title: "Image",
      dataIndex: "media",
      key: "media",
      render: (value, record) => (
        <Link to={`/categorylist/${record._id}`}>
          <img
            src={
              value
                ? value
                : "https://quiz-app1.s3.ap-south-1.amazonaws.com/media/images/Offline.jpg"
            }
            alt="Login"
            className={styles.image}
          />
        </Link>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (value, record) => (
        <Link to={`/categorylist/${record._id}`}>{value}</Link>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Parent",
      dataIndex: "parent",
      key: "parent",
      render: (data) => (data != null ? data.name : "Main Category"),
      filterMultiple: false,
      filters: [
        {
          text: "Sub Category",
          value: "6",
        },
        {
          text: "Parent Category",
          value: "Main",
        },
      ],
      onFilter: (value, record) => {
        if (value === "Main") {
          if (record?.parent?.name) return false;
          return true;
        } else if (value === "6") {
          if (record?.parent?.name) return true;
          return false;
        }
      },
      filterSearch: true,
      // width: "40%",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",

      render: (data) => dateConverter(data),
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          {record?.is_active === false ? (
            <Popconfirm
              title="Are you sure to make it Online"
              onConfirm={() =>
                callSetCategoryOnlineOffline({
                  id: record?._id,
                  is_active: true,
                })
              }
              okText="Confirm"
              cancelText="Cancel"
              placement="leftTop"
              okButtonProps={{
                danger: true,
              }}
            >
              <Tooltip
                placement="topLeft"
                title="This will diplay Category to all users"
              >
                <Button type="danger" className={styles.button}>
                  Offline
                </Button>
              </Tooltip>
            </Popconfirm>
          ) : (
            <Tooltip
              placement="topLeft"
              title="This will hide Category from all users"
            >
              <Button
                className={styles.button}
                style={{
                  backgroundColor: "green",
                  color: "white",
                  fontWeight: "normal",
                }}
                onClick={() =>
                  callSetCategoryOnlineOffline({
                    id: record?._id,
                    is_active: false,
                  })
                }
              >
                Online
              </Button>
            </Tooltip>
          )}
          <Link to={`/categorylist/${record._id}`}>
            <Button type="primary">Detail View</Button>
          </Link>
        </Space>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <Spin spinning={loading || detailLoading}>
          <div className={styles.category}>
            <div className={styles.imageAncContent}>
              <div
                style={{
                  justifyContent: "center",
                  alignSelf: "center",
                  margin: 4,
                }}
              >
                {singleCategory?.media && (
                  <img
                    src={singleCategory?.media}
                    alt="QuestionImage"
                    style={{
                      width: "150px",
                      height: "100px",
                      border: "solid",
                      borderwidth: "0.1",
                      borderColor: "blue",
                      borderRadius: 7,
                    }}
                  />
                )}
              </div>
              <div>
                <div>
                  Category Name:<b> {singleCategory?.name} </b>
                </div>
                {/* {console.log("value in state", singleCategory)} */}
                <div>
                  Category Descripton: <b> {singleCategory?.description}</b>
                </div>
                <div>
                  Language: <b> {singleCategory?.language}</b>
                </div>
                <div>
                  Parent: <b> {singleCategory?.parent?.name}</b>
                </div>
                <div>
                  Created By: <b>{singleCategory?.createdby}</b>
                </div>
                <div>
                  Created At:<b> {dateConverter(singleCategory?.createdAt)}</b>{" "}
                </div>
              </div>
            </div>
            <div>
              <Button onClick={() => navigate(-1)} className={styles.button}>
                Go Back
              </Button>
              <Tooltip placement="topLeft" title="Used to edit this category ">
                <Button
                  type="primary"
                  onClick={() => setEditModalVisible(true)}
                  className={styles.button}
                >
                  Edit
                </Button>
              </Tooltip>

              <Popconfirm
                title="Are you sure to delete this task?"
                onConfirm={() => deleteRecord(params.id)}
                // onCancel={cancel}
                // getPopupContainer={(node) => node.parentNode}
                okText="Confirm"
                cancelText="Cancel"
                placement="rightTop"
                okButtonProps={{
                  danger: true,
                }}
              >
                <Tooltip
                  placement="topLeft"
                  title="This will delete category, if it does not have futher categories"
                >
                  <Button
                    type="primary"
                    danger
                    className={styles.button}
                    loading={deleteLoading}
                  >
                    Delete
                  </Button>
                </Tooltip>
              </Popconfirm>

              <Tooltip placement="topLeft" title="Quiz List">
                <Link
                  to={"/quizincategory"}
                  state={{
                    from: { id: params.id, singleCategory },
                  }}
                >
                  <Button type="primary" className={styles.button}>
                    Quiz List
                  </Button>
                </Link>
              </Tooltip>
              <Button
                type="primary"
                className={styles.button}
                onClick={() => setAddCategoryModelVisible(true)}
              >
                Add New Sub-Category
              </Button>
            </div>
          </div>
          <div className={styles.table}>
            <Table
              columns={columns}
              dataSource={categoryList}
              onChange={onChange}
            />
          </div>
        </Spin>
      </div>
      {editModalVisible ? (
        <EditCategoryComponent
          visible={editModalVisible}
          data={singleCategory}
          handleOk={(values) => {
            updateRecordFuntion(values);
            setEditModalVisible(false);
          }}
          handleCancel={() => {
            setEditModalVisible(false);
          }}
        />
      ) : null}

      {updateCustomIdModal ? (
        <UpdateCustomId
          visible={updateCustomIdModal}
          handleCancel1={() => {
            setupdateCustomIdModal(false);
          }}
          defaultId={currentCustomId?.customId}
          id={currentCustomId?._id}
          allDone={() => setCategorylistFunction()}
        />
      ) : null}

      {/* Add New Sub Category Modal Start */}
      <div>
        <Modal
          visible={addCategoryModelVisible}
          title="Add Sub Category In This Category"
          footer={null}
          destroyOnClose={true}
          width={1000}
          onCancel={() => {
            setAddCategoryModelVisible(false);
          }}
        >
          <AddNewMainCategory
            callBack={() => {
              setAddCategoryModelVisible(false);
              setCategorylistFunction();
            }}
            categoryFromModel={singleCategory}
          />
        </Modal>
      </div>
      {/* Add New Sub Category Modal End */}
    </div>
  );
};
export default CategoryDetail;
