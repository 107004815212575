import "./App.css";

import { BrowserRouter, Route, Routes, Outlet } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import Login from "./pages/Login";
import TermAndConditions from "./pages/TermAndConditions";
import React from "react";
import SecurityLayout from "./Layouts/SecurityLayout";
import DashboardLayout from "./Layouts/DashboardLayout";
import AddNewMainCategory from "./pages/category/addNewMainCategory/AddNewMainCategory";
import AddNewQuestion from "./pages/Question/AddNewQuestion";
import Rough from "./pages/rough/Rough";
import CategoryList from "./pages/category/CategoryList/CategoryList";
import UserList from "./pages/User/UserList/UserList";
import CategoryDetail from "./pages/category/CategoryDetail/CategoryDetail";
import QuestionList from "./pages/Question/QuestionList";

import AddNewQuiz from "./pages/Quiz/AddQuiz";
import AddQuestionToQuiz from "./pages/Quiz/AddQuestionToQuiz";
import QuizList from "./pages/Quiz/QuizList";
import AddComprehension from "./pages/Comprehension/AddComprehension";
import ComprehensionList from "./pages/Comprehension/ComprehensionList";
import Profile from "./pages/User/UserList/Profile";
import HomePage from "./pages/Home/HomePage";
import AddPoll from "./pages/Poll/AddPoll";
import QuizInCategory from "./pages/Quiz/QuizInCategory";
import QuestionByCategory from "./pages/Question/QuestionByCategory";
function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/auth/login" element={<Login />} />

          <Route path="/termandconditions" element={<TermAndConditions />} />

          <Route path="/" element={<SecurityLayout />}>
            {/* i create securityLayout to protect route from unauthorized access */}
            <Route path="/" element={<DashboardLayout />}>
              <Route path="" element={<HomePage />} />
              <Route
                path="/addnewmaincategory"
                element={<AddNewMainCategory />}
              />
              <Route path="/addnewquestion" element={<AddNewQuestion />} />
              <Route path="/categorylist" element={<Outlet />}>
                <Route path="" element={<CategoryList />} />
                <Route path=":id" element={<CategoryDetail />} />
              </Route>
              <Route path="/rough" element={<Rough />} />
              {/* Question Route Start */}
              <Route path="/questionlist" element={<QuestionList />} />

              {/* Question Route End*/}
              <Route path="/userlist" element={<UserList />} />
              {/* Quiz Route Start */}
              <Route path="/addquiz" element={<AddNewQuiz />} />

              {/* testing */}
              <Route path="/quizlist" element={<Outlet />}>
                <Route path="" element={<QuizList />} />
                <Route path=":id" element={<AddQuestionToQuiz />} />
              </Route>
              {/* testing end */}

              {/* Quiz Route End */}
              {/* Comprehension Route Start */}
              <Route path="/addcomprehension" element={<AddComprehension />} />
              <Route
                path="/comprehensionlist"
                element={<ComprehensionList />}
              />

              {/* Comprehension Route End*/}
              <Route path="/profile" element={<Profile />} />
              <Route path="/addpoll" element={<AddPoll />} />
              <Route
                path="/questionbycategory"
                element={<QuestionByCategory />}
              />

              <Route path="/quizincategory" element={<QuizInCategory />} />
            </Route>

            {/* DashboardLayout contain protected user login etc routes */}
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
