import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import {
  Form,
  Button,
  message,
  Modal,
  notification,
  Pagination,
  Popconfirm,
  Select,
  Space,
  Spin,
  Table,
  Tooltip,
} from "antd";
import { getCategoryListForDropDownMenu } from "../../../services/categoryServices";
import {
  deleteQuestion,
  getQuestionList,
} from "../../../services/questionServices";
import AddUpdateQuestionComponent from "../Component/AddUpdateQuestionComponent";
import AddNewQuestion from "../AddNewQuestion";
const QuestionByCategory = () => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const [language, setLanguage] = useState();
  const [category, setCategory] = useState();
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(10);
  const [questionList, setQuestionList] = useState({
    data: [],
    totalRecords: 0,
  });

  const [dropDownCategory, setDropDownCategory] = useState();
  const [visible, setVisible] = useState(false);

  const [recordId, setRecordId] = useState("");
  const [addQuestionModelVisible, setAddQuestionModelVisible] = useState(false);
  useEffect(() => {
    callGetCategoryList();
    setCategory(null);
    form.resetFields();
  }, [language]);

  useEffect(() => {
    getQuestionListFunction();
  }, [category, start, limit]);

  //sorting dropdown list start
  const compareName = (a, b) => {
    // converting to uppercase to have case-insensitive comparison
    const name1 = a.name.toUpperCase();
    const name2 = b.name.toUpperCase();

    let comparison = 0;

    if (name1 > name2) {
      comparison = 1;
    } else if (name1 < name2) {
      comparison = -1;
    }
    return comparison;
  };
  //sorting dropdown list end
  const callGetCategoryList = async () => {
    const result = await getCategoryListForDropDownMenu({
      query: { language },
    });
    const sortedData = await result?.data?.sort(compareName);

    setDropDownCategory(sortedData);
  };

  const onChange = (value) => {
    console.log(`selected ${value}`);
    setCategory(value);
  };

  const deleteQuestionFunction = async (id) => {
    try {
      console.log("ID to delete", id);
      setLoading(true);
      const result = await deleteQuestion(id);
      setLoading(false);
      if (result?.message === "success") {
        message.success("Question Deleted From Database");
      } else message.warning("Something Went Wrong");

      getQuestionListFunction();
    } catch (error) {
      notification.error({
        message: `${error?.status}: ${error?.statusText}`,
        description: error?.data?.message,
      });
    }
  };

  const getQuestionListFunction = async () => {
    try {
      if (category) {
        setLoading(true);
        const result = await getQuestionList({
          query: { start, limit, category, type: "boolean" },
        });

        setQuestionList(result);
        setLoading(false);
      }
    } catch (error) {
      console.log("error: ", error);
      notification.error({
        message: `${error?.status}: ${error?.statusText}`,
        description: error?.data?.message,
      });
      setLoading(false);
    }
  };

  const columns = [
    {
      title: <div className={styles.heading}>Id </div>,
      key: "index",
      width: 55,
      render: (text, record, index) => (
        <div className={styles.heading}>{index + 1}</div>
      ),
    },
    {
      title: <div className={styles.heading}>TITLE </div>,
      dataIndex: "title",
      key: "title",
      render: (value, record) =>
        record?.title?.type === "text" ? (
          record?.title?.text
        ) : (
          <img
            src={record?.title?.text}
            alt="Login"
            style={{ height: "80px", width: "120px" }}
          />
        ),
    },

    {
      title: <div className={styles.heading}>Question Type</div>,
      dataIndex: "type",
      key: "type",
    },
    {
      title: <div className={styles.heading}>Option A</div>,
      dataIndex: "option1",
      key: "option1",
      render: (value, record) =>
        record?.option1?.type === "text" ? (
          record?.option1?.text
        ) : (
          <img
            src={record?.option1?.text}
            alt="Login"
            style={{ height: "80px", width: "120px" }}
          />
        ),
    },
    {
      title: <div className={styles.heading}>Option B</div>,
      dataIndex: "option2",
      key: "option2",
      render: (value, record) =>
        record?.option2?.type === "text" ? (
          record?.option2?.text
        ) : (
          <img
            src={record?.option2?.text}
            alt="Login"
            style={{ height: "80px", width: "120px" }}
          />
        ),
    },
    {
      title: <div className={styles.heading}>Option C</div>,
      dataIndex: "option3",
      key: "option3",
      render: (value, record) =>
        record?.option3?.type === "text" ? (
          record?.option3?.text ? (
            record?.option3?.text
          ) : null
        ) : record?.option3?.text ? (
          <img
            src={record?.option3?.text}
            alt="Login"
            style={{ height: "80px", width: "120px" }}
          />
        ) : null,
    },
    {
      title: <div className={styles.heading}>Option D</div>,
      dataIndex: "option4",
      key: "option4",
      render: (value, record) =>
        record?.option4?.type === "text" ? (
          record?.option4?.text ? (
            record?.option4?.text
          ) : null
        ) : record?.option4?.text ? (
          <img
            src={record?.option4?.text}
            alt="Login"
            style={{ height: "80px", width: "120px" }}
          />
        ) : null,
    },
    {
      title: <div className={styles.heading}>Answer</div>,
      dataIndex: "answer",
      key: "answer",
      width: 100,
      render: (value, record) => {
        return <div dangerouslySetInnerHTML={{ __html: value }} />;
      },
    },
    {
      title: (
        <div style={{ color: "red", fontWeight: 800, letterSpacing: "2px" }}>
          Action
        </div>
      ),
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Popconfirm
            title="Are you sure to delete this task?"
            onConfirm={() => deleteQuestionFunction(record._id)}
            // onCancel={cancel}
            // getPopupContainer={(node) => node.parentNode}
            okText="Confirm"
            cancelText="Cancel"
            placement="leftTop"
            okButtonProps={{
              danger: true,
            }}
          >
            <Tooltip
              placement="topLeft"
              title="This will delete question and reference from all quizes. Be careful while using this"
            >
              <Button type="primary" danger>
                Delete
              </Button>
            </Tooltip>
          </Popconfirm>
          <Button
            type="primary"
            onClick={() => {
              setRecordId(record?._id);
              setVisible(true);
            }}
          >
            Edit
          </Button>
        </Space>
      ),
    },
  ];
  const onShowSizeChange = (current, pageSize) => {
    setLimit(pageSize);
    // console.log(current, pageSize);
  };
  function handleChange(value) {
    console.log(`selected ${value}`);
    setLanguage(value);
  }
  const categoryColorScheme = (name) => {
    let result;
    language === "English" && (result = name.indexOf("PSTET 1"));
    language === "Punjabi" && (result = name.indexOf("ਪੀ.ਐੱਸ.ਟੀ.ਈ.ਟੀ. 1"));

    let color = "white";
    let textColor = "black";
    if (result >= 0) {
      color = "blue";
      textColor = "white";
    }
    return (
      <div
        style={{
          backgroundColor: color,
          color: textColor,
          fontWeight: 600,
          padding: 3,
        }}
      >
        {name}
      </div>
    );
  };

  return (
    <div style={{ margin: 15 }}>
      <div style={{ fontWeight: 700, marginBottom: 8 }}>
        Question by Category (Select Category to see how many questions are
        added in particular category)
      </div>
      <Form form={form} name="basic" layout="horizontal" autoComplete="off">
        <Form.Item
          label="Select Language"
          name="language"
          initialValue={language}
        >
          <Select
            style={{
              width: 220,
            }}
            onChange={handleChange}
            placeholder="Select a Language"
          >
            <Select.Option value="Punjabi">Punjabi </Select.Option>
            <Select.Option value="English">English </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="category"
          label="Category"
          rules={[
            {
              required: true,
              message: "Please select Category First",
            },
          ]}
        >
          <Select
            optionFilterProp="children"
            placeholder="Select a Category"
            size="large"
            onChange={onChange}
            style={{
              width: "90%",
              marginTop: 5,
              marginBottom: 9,
            }}
          >
            {dropDownCategory?.map((option) => (
              <Option value={option?._id} key={option?._id}>
                {categoryColorScheme(option?.name)}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>

      <div>
        {category && (
          <Button
            type="primary"
            onClick={() => setAddQuestionModelVisible(true)}
            style={{ marginBottom: 6 }}
          >
            Add New Question In This Category
          </Button>
        )}
      </div>
      <div>
        <Spin spinning={loading} size="large">
          <Table
            columns={columns}
            dataSource={questionList?.data}
            pagination={false}
            rowKey={(record) => record?._id}
          />
          <Pagination
            total={questionList?.totalRecords}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            showSizeChanger
            pageSize={limit}
            onShowSizeChange={onShowSizeChange}
            current={start / limit + 1}
            onChange={(page, pageSize) => {
              setStart((page - 1) * limit);
            }}
          />
        </Spin>
      </div>
      {/* Edit Modal Start Here */}
      <div>
        <Modal
          visible={visible}
          title="Update Question"
          closable={false}
          footer={null}
          destroyOnClose={true}
          width={1000}
        >
          {recordId !== "" && (
            <AddUpdateQuestionComponent
              id={recordId}
              callBack={() => {
                setVisible(false);
                setRecordId("");
                getQuestionListFunction();
              }}
            />
          )}
        </Modal>
      </div>
      {/* Edit Modal End Here */}
      {/* Add Question Modal Start */}
      <div>
        <Modal
          visible={addQuestionModelVisible}
          title="Add Question In This Category"
          footer={null}
          destroyOnClose={true}
          width={1000}
          onCancel={() => {
            setAddQuestionModelVisible(false);
          }}
        >
          <AddNewQuestion
            callBack={() => {
              setAddQuestionModelVisible(false);
              getQuestionListFunction();
            }}
            categoryFromModel={category}
            languageFromModel={language}
          />
        </Modal>
      </div>
      {/* Add Question Modal End */}
    </div>
  );
};

export default QuestionByCategory;
