import { Layout, Menu, Avatar, Popover, message, notification } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UserOutlined, CaretDownOutlined } from "@ant-design/icons";

import { Link, Outlet } from "react-router-dom";
import { updateUserLoginDetails } from "../redux/actions/userAction";
import { USER_LOGOUT } from "../redux/constants/authConstants";
import { logoutUser } from "../services/userServices";

const { Header, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const DashboardLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userInfo } = useSelector((state) => state.userLogin);
  // const user = localStorage.getItem("userInfo");
  const user = JSON.parse(localStorage.getItem("userInfo"));

  const callLogoutUser = async () => {
    try {
      const response = await logoutUser({
        Headers: { authorization: user?.refreshToken },
      });
      if (response.message === "success") {
        message.success("You are logout");
        dispatch({ type: USER_LOGOUT });
        localStorage.clear();
        // <Redirect to="/login" />;
        //move to home page by link
        navigate("/auth/login");
      }
    } catch (error) {
      notification.error({
        message: `${error?.status}: ${error?.statusText}`,
        description: error?.data?.message,
      });
    }
  };

  const menuList = (
    <div>
      <div style={{ padding: "5px" }}>
        <Link to="/profile">Profile</Link>
      </div>
      <div
        onClick={() => {
          callLogoutUser();
        }}
        style={{ padding: "5px", hover: { backgroundColor: "blue" } }}
      >
        Logout
      </div>
    </div>
  );

  const userDataUpdator = () => {
    dispatch(updateUserLoginDetails());
    //updateUserLoginDetails() is used to check the authenticity of the user. i user is valid or not. we get user token and verify it, get user data as respond and store in local storage. set data in userInfo
  };
  useEffect(() => {
    userDataUpdator();
  }, []);

  return (
    <Layout style={{ height: "100%" }}>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
      >
        <div className="logo" />
        <Menu theme="dark" mode="inline">
          <Menu.Item key="1">
            <Link to="/">Home</Link>
          </Menu.Item>
          {user.role === "super-admin" && (
            <SubMenu key="CategoryZone" title="Category Zone">
              <Menu.Item key="CategoryZone1">
                <Link to="/addnewmaincategory">Add Main Category</Link>
              </Menu.Item>
              <Menu.Item key="CategoryZone2">
                <Link to="/categorylist">Category List</Link>
              </Menu.Item>
            </SubMenu>
          )}

          <SubMenu key="QuestionZone" title="Question Zone">
            <Menu.Item key="QuestionZone1">
              <Link to="/addnewquestion">Add Questions</Link>
            </Menu.Item>
            <Menu.Item key="QuestionZone2">
              <Link to="/questionlist">Questions List</Link>
            </Menu.Item>
            <Menu.Item key="QuestionZone3">
              <Link to="/questionbycategory">AddCategoryWise</Link>
            </Menu.Item>
          </SubMenu>

          <SubMenu key="comprehensionZone" title="Comprehension Zone">
            <Menu.Item key="comprehensionZone1">
              <Link to="/addcomprehension">Add Comprehension</Link>
            </Menu.Item>
            <Menu.Item key="comprehensionZone2">
              <Link to="/comprehensionlist">Comprehension List</Link>
            </Menu.Item>
          </SubMenu>

          <SubMenu key="QuizZone" title="Quiz Zone">
            <Menu.Item key="QuizZone1">
              <Link to="/addquiz">Add Quiz</Link>
            </Menu.Item>
            <Menu.Item key="QuizZone2">
              <Link to="/quizlist">Quiz List</Link>
            </Menu.Item>
          </SubMenu>

          <SubMenu key="PollZone" title="Poll Zone">
            <Menu.Item key="PollZone1">
              <Link to="/addpoll">Add Poll</Link>
            </Menu.Item>
            {/* <Menu.Item key="PollZone2">
              <Link to="/quizlist">Quiz List</Link>
            </Menu.Item> */}
          </SubMenu>
          {user.role === "super-admin" && (
            <SubMenu key="User" title="Users">
              <Menu.Item key="User1">
                <Link to="/userlist">User List</Link>
              </Menu.Item>
            </SubMenu>
          )}

          <Menu.Item key="3">nav 3</Menu.Item>
          <Menu.Item key="4">
            <Link to="/rough">Rough</Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Header
          className="site-layout-sub-header-background"
          style={{
            padding: 0,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            paddingRight: "1rem",
          }}
        >
          {/* <Dropdown style={{ zIndex: 100 }} overlay={menu} trigger={["click"]}> */}
          <Popover
            content={menuList}
            title={null}
            trigger="click"
            placement="bottomRight"
            // visible={this.state.visible}
            // onVisibleChange={this.handleVisibleChange}
          >
            <div>
              <Avatar icon={<UserOutlined />} />{" "}
              <span
                style={{
                  color: "white",
                  marginLeft: "1rem",
                  textTransform: "capitalize",
                }}
              >
                {userInfo?.name}
                <CaretDownOutlined />
              </span>
            </div>
          </Popover>
          {/* </Dropdown> */}
        </Header>
        <Outlet />
        {/* Render securityLayout route elements here by using Outlet */}
        <Footer style={{ textAlign: "center" }}>Quiz app</Footer>
      </Layout>
    </Layout>
  );
};

export default DashboardLayout;
