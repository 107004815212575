import {
  Button,
  message,
  notification,
  Pagination,
  Popconfirm,
  Space,
  Table,
  Modal,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { getSingleComprehensionDetail } from "../../../../services/comprehensionServices";
import {
  deleteQuestion,
  getComprehensionQuestionList,
} from "../../../../services/questionServices";
import AddUpdateQuestionComponent from "../../../Question/Component/AddUpdateQuestionComponent";

const ViewComprehensionAndQuestions = ({ id }) => {
  const [singleComprehension, setsingleComprehension] = useState();
  const [questionList, setQuestionList] = useState({
    data: [],
    totalRecords: 0,
  });
  const [recordId, setRecordId] = useState();
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(10);
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    callgetComprehensionQuestionList(id);
  }, [start, limit]);

  console.log("comprehension ID", id);
  useEffect(() => {
    if (id) {
      callGetSingleComprehensionDetail(id);
      callgetComprehensionQuestionList(id);
    }
  }, [id]);

  const callGetSingleComprehensionDetail = async (id) => {
    try {
      // setLoading(true);
      const result = await getSingleComprehensionDetail(id);
      // setLoading(false);
      setsingleComprehension(result?.data);
      // console.log(comprehensionList);
    } catch (error) {
      console.log("error: ", error);
      notification.error({
        message: `${error?.status}: ${error?.statusText}`,
        description: error?.data?.message,
      });
      // setLoading(false);
    }
  };

  const callgetComprehensionQuestionList = async (id) => {
    try {
      setLoading(true);

      const result = await getComprehensionQuestionList({
        id,
        query: { start, limit },
      });

      setQuestionList(result);
      // console.log(comprehensionList);
      setLoading(false);
    } catch (error) {
      console.log("error: ", error);
      notification.error({
        message: `${error?.status}: ${error?.statusText}`,
        description: error?.data?.message,
      });
      // setLoading(false);
    }
  };

  const deleteQuestionFunction = async (questionId) => {
    try {
      console.log("questionID to delete", questionId);
      // setLoading(true);
      const result = await deleteQuestion(questionId);
      // setLoading(false);
      if (result?.message === "success") {
        message.success("Question Deleted From Database");
        setQuestionList((prevState) => {
          return {
            data: prevState?.data?.filter((item) => item._id !== questionId),
            totalRecords: prevState?.totalRecords - 1,
          };
        });
      } else message.warning("Something Went Wrong");
    } catch (error) {
      notification.error({
        message: `${error?.status}: ${error?.statusText}`,
        description: error?.data?.message,
      });
    }
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (value, record) =>
        record?.title?.type === "text" ? (
          record?.title?.text
        ) : (
          <img
            src={record?.title?.text}
            alt="Login"
            style={{ height: "80px", width: "120px" }}
          />
        ),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "_id",
      render: (value, record) => record?.category?.name,
    },
    {
      title: "Question Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Option A",
      dataIndex: "option1",
      key: "option1",
      render: (value, record) =>
        record?.option1?.type === "text" ? (
          record?.option1?.text
        ) : (
          <img
            src={record?.option1?.text}
            alt="Login"
            style={{ height: "80px", width: "120px" }}
          />
        ),
    },
    {
      title: "Option B",
      dataIndex: "option2",
      key: "option2",
      render: (value, record) =>
        record?.option2?.type === "text" ? (
          record?.option2?.text
        ) : (
          <img
            src={record?.option2?.text}
            alt="Login"
            style={{ height: "80px", width: "120px" }}
          />
        ),
    },
    {
      title: "Option C",
      dataIndex: "option3",
      key: "option3",
      render: (value, record) =>
        record?.option3?.type === "text" ? (
          record?.option3?.text ? (
            record?.option3?.text
          ) : null
        ) : record?.option3?.text ? (
          <img
            src={record?.option3?.text}
            alt="Login"
            style={{ height: "80px", width: "120px" }}
          />
        ) : null,
    },
    {
      title: "Option D",
      dataIndex: "option4",
      key: "option4",
      render: (value, record) =>
        record?.option4?.type === "text" ? (
          record?.option4?.text ? (
            record?.option4?.text
          ) : null
        ) : record?.option4?.text ? (
          <img
            src={record?.option4?.text}
            alt="Login"
            style={{ height: "80px", width: "120px" }}
          />
        ) : null,
    },
    {
      title: "Answer",
      dataIndex: "answer",
      key: "answer",
      render: (value, record) => {
        return <div dangerouslySetInnerHTML={{ __html: value }} />;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Popconfirm
            title="Are you sure to delete this task?"
            onConfirm={() => deleteQuestionFunction(record._id)}
            // onCancel={cancel}
            // getPopupContainer={(node) => node.parentNode}
            okText="Confirm"
            cancelText="Cancel"
            placement="leftTop"
            okButtonProps={{
              danger: true,
            }}
          >
            {" "}
            <Tooltip
              title="This will delete question from comprehension"
              placement="topLeft"
            >
              <Button type="primary" danger>
                Delete
              </Button>
            </Tooltip>
          </Popconfirm>
          <Tooltip
            title="Edit question belong to comprehension"
            placement="topLeft"
          >
            <Button
              type="primary"
              onClick={() => {
                setRecordId(record?._id);
                setVisible(true);
              }}
            >
              Edit
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ];
  return (
    <div>
      <div>
        <div>
          <strong>Title:</strong> {singleComprehension?.slug}{" "}
        </div>
        <div>
          <strong>Comprehension:</strong> {singleComprehension?.comprehension}{" "}
        </div>
      </div>
      <div>
        <div>
          <strong>Question Table</strong>
        </div>
        <div>
          This table only contain question related to this comprehension.
        </div>
        <div>
          <Table
            columns={columns}
            dataSource={questionList?.data}
            pagination={false}
            loading={loading}
          />
          <Pagination
            total={questionList?.totalRecords}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            pageSize={limit}
            current={start / limit + 1}
            onChange={(page, pageSize) => {
              setStart((page - 1) * limit);
            }}
          />
        </div>
        {/* Edit Modal Start Here */}
        <div>
          <Modal
            visible={visible}
            title="Update Question"
            // onOk={() => form.submit()}
            onCancel={() => {
              setVisible(false);
            }}
            footer={null}
            width={1000}
            destroyOnClos={true}
          >
            <AddUpdateQuestionComponent
              id={recordId}
              type={"update"}
              callBack={() => {
                setVisible(false);
                callgetComprehensionQuestionList(id);
              }}
            />
          </Modal>
        </div>
        {/* Edit Modal End Here */}
      </div>
    </div>
  );
};

export default ViewComprehensionAndQuestions;
