import {
  Button,
  Modal,
  Checkbox,
  Form,
  Input,
  notification,
  message,
} from "antd";
import React, { useState } from "react";
import { updateCustomIdService } from "../../services/categoryServices";

const UpdateCustomId = ({ visible, handleCancel1, defaultId, id, allDone }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState("Content of the modal");

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      handleCancel1();
      setConfirmLoading(false);
    }, 2000);
  };
  console.log("defaultId we have", defaultId);

  const onFinish = async (values) => {
    console.log("Success:", values);

    try {
      //   setLoading(true);

      const response = await updateCustomIdService({ id, values });

      if (response.message !== "success") {
        throw new Error(
          "Something went wrong. Please try again or contact administrator"
        );
      }
      message.success("New category is added in a Database");
      allDone();
      handleOk();

      //   setLoading(false);
    } catch (error) {
      notification.error({
        message: `${error?.status}: ${error?.statusText}`,
        description: error?.data?.message,
      });
      //   setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      <Modal
        title="Title"
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel1}
        footer={null}
      >
        {/* <p>{modalText}</p> */}
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Custom ID"
            name="customId"
            initialValue={defaultId}
            rules={[
              {
                required: true,
                message: "Update Custom Id",
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              loading={confirmLoading}
              style={{ borderRadius: 5 }}
            >
              Submit
            </Button>
            <Button
              onClick={() => handleCancel1()}
              style={{ marginLeft: 7, borderRadius: 5 }}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateCustomId;
