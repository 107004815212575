import React, { useEffect, useState } from "react";
import {
  Form,
  Select,
  Modal,
  notification,
  Table,
  Spin,
  Pagination,
  message,
} from "antd";
import { getQuestionList } from "../../../../services/questionServices";
import { getCategoryListForDropDownMenu } from "../../../../services/categoryServices";
import { addQuestionToQuiz } from "../../../../services/quizServices";
import {
  categoryColorScheme,
  sortDropDownMenu,
} from "../../../../components/SortAndColorDropDownMenu";
import styles from "./styles.module.css";
const AddQuestionModel = ({
  visible,
  callBack,
  setVisible,
  defaultCategory,
  id,
}) => {
  const [data, setData] = useState();

  const [form] = Form.useForm();
  const [category, setcategory] = useState(defaultCategory);
  const { Option } = Select;

  const [questionsIds, setQuestionsIds] = useState();
  const [loading, setLoading] = useState();
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(10);
  const [language, setLanguage] = useState();
  const [questionList, setQuestionList] = useState({
    data: [],
    totalRecords: 0,
  });

  useEffect(() => {
    setcategory(defaultCategory);
  }, [defaultCategory]);

  useEffect(() => {
    if (visible) getQuestionListFunction();
  }, [start, limit, category, visible]);

  useEffect(() => {
    if (visible) callGetCategoryList();
    form.resetFields();
  }, [visible, language]);

  const getQuestionListFunction = async () => {
    try {
      if (category) {
        setLoading(true);
        const result = await getQuestionList({
          query: { start, limit, category, type: "boolean", quiz: id },
        });
        setLoading(false);
        setQuestionList(result);
      }
    } catch (error) {
      console.log("error: ", error);
      notification.error({
        message: `${error?.status}: ${error?.statusText}`,
        description: error?.data?.message,
      });
      // setLoading(false);
    }
  };

  const callGetCategoryList = async () => {
    const result = await getCategoryListForDropDownMenu({
      query: { language },
    });
    const sortedData = await result?.data?.sort(sortDropDownMenu);
    setData(sortedData);
  };

  const columns = [
    {
      title: <div className={styles.heading}>Id </div>,
      key: "index",
      render: (text, record, index) => (
        <div className={styles.heading}>{index + 1}</div>
      ),
      width: 55,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (value, record) =>
        record?.title?.type === "text" ? (
          record?.title?.text
        ) : (
          <img
            src={record?.title?.text}
            alt="Login"
            style={{ height: "80px", width: "120px" }}
          />
        ),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "_id",
      render: (value, record) => record?.category?.name,
    },

    {
      title: "Option Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Question Type",
      dataIndex: "comprehension",
      key: "comprehension",
      render: (value, record) => {
        return value != null ? (
          <div style={{ color: "blue", fontWeight: "600" }}>
            "Comprehension Question"
          </div>
        ) : (
          "Simple Question"
        );
      },
    },
  ];

  // model related codding end here
  function onChange(value) {
    console.log(`selected ${value}`);
    setcategory(value);
  }

  function onSearch(val) {
    console.log("search:", val);
  }

  const submitQuestionIds = async () => {
    console.log("in submit id ", id);

    console.log("in submit ids", questionsIds);
    const payload = {
      questionsIds: questionsIds,
    };
    console.log("payload", payload);
    try {
      const response = await addQuestionToQuiz({
        id,
        payload,
      });

      if (response.message !== "success") {
        throw new Error(
          "Something went wrong. Please try again or contact administrator"
        );
      }
      if (response.message === "success") {
        message.success("Questions is added in a Quiz");
        getQuestionListFunction();
      }
    } catch (error) {
      console.log("error: ", error);
      notification.error({
        message: `${error?.status}: ${error?.statusText}`,
        description: error?.data?.message,
      });
    }
  };
  function handleChange(value) {
    console.log(`selected ${value}`);
    setLanguage(value);
  }
  return (
    <div>
      <Modal
        title="Add Question to Quiz"
        centered
        visible={visible}
        // onOk={() => setVisible(false)}
        onOk={() => submitQuestionIds()}
        cancelText="Return"
        onCancel={() => {
          setVisible(false);
          setcategory(defaultCategory);
          callBack();
        }}
        width={1000}
      >
        <div>
          <Form form={form} name="basic" layout="horizontal" autoComplete="off">
            <Form.Item
              label="Select Language"
              name="language"
              initialValue={language}
            >
              <Select
                style={{
                  width: 120,
                }}
                onChange={handleChange}
              >
                <Select.Option value="Punjabi">Punjabi </Select.Option>
                <Select.Option value="English">English </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="category"
              label="Category"
              rules={[
                {
                  required: true,
                  message: "Please select Category First",
                },
              ]}
            >
              <Select
                placeholder="Select category to add questions"
                optionFilterProp="children"
                onChange={onChange}
                value={category}
                style={{ width: "100%" }}
              >
                {data &&
                  data?.map((option) => (
                    <Select.Option key={option?._id} value={option?._id}>
                      {categoryColorScheme({ name: option?.name, language })}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Form>

          <div></div>
          <div>
            <div>All questions which we can add in Quiz is showing below</div>
            <div>
              <Spin spinning={loading} size="large">
                <div>
                  <>
                    <Table
                      rowSelection={{
                        // selectedRowKeys,
                        onChange: (selectedRowKeys, selectedRows) => {
                          console.log("datasource: ", questionList.data);
                          console.log(
                            "selectedRowKeys changed: ",
                            selectedRowKeys,
                            selectedRows
                          );
                          // this.setState({ selectedRowKeys });
                          setQuestionsIds(selectedRowKeys);
                        },
                      }}
                      columns={columns}
                      dataSource={questionList.data.map((item) => {
                        return {
                          ...item,
                          key: item._id,
                        };
                      })}
                      pagination={false}
                    />
                    <Pagination
                      total={questionList?.totalRecords}
                      showTotal={(total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`
                      }
                      defaultPageSize={10}
                      defaultCurrent={1}
                      onChange={(page, pageSize) => {
                        setStart((page - 1) * limit);
                      }}
                    />
                  </>
                </div>
              </Spin>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddQuestionModel;
