import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  notification,
  message,
  Upload,
  Spin,
} from "antd";
import styles from "../../AddQuestionToQuiz/styles.module.css";
import { getCategoryListForDropDownMenu } from "../../../../services/categoryServices";
import { postQuiz, updateQuiz } from "../../../../services/quizServices";
import { UploadOutlined } from "@ant-design/icons";
import {
  categoryColorScheme,
  sortDropDownMenu,
} from "../../../../components/SortAndColorDropDownMenu";
const AddEditQuiz = ({
  type,
  onSuccess,
  details = {},
  categoryFromModel,
  callBack,
}) => {
  const { _id: id, name, category, lives, description, language } = details;
  const [language1, setLanguage1] = useState(
    (language && language) ||
      (categoryFromModel?.language && categoryFromModel?.language) ||
      "Punjabi"
  );
  const [data, setData] = useState();
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [formloading, setFormLoading] = useState(false);
  const user = localStorage.getItem("userInfo");
  //we get user detail from localstorage and used it to send in payload as created by as user id
  var formData = new FormData();
  const [file, setFile] = useState([]);
  const userInfoObject = JSON.parse(user);

  useEffect(() => {
    callGetCategoryList();
    form.resetFields();
  }, [language1]);
  function handleChange(value) {
    console.log(`selected ${value}`);
    setLanguage1(value);
  }
  const callGetCategoryList = async () => {
    setFormLoading(true);
    const result = await getCategoryListForDropDownMenu({
      query: { language: language1 },
    });
    const sortedData = await result?.data?.sort(sortDropDownMenu);
    setData(sortedData);
    setFormLoading(false);
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      // const payload = {
      //   name: values.name,
      //   description: values.description,
      //   category: values.category,
      //   lives: values.lives,
      //   createdby: userInfoObject._id,
      // };
      formData.append("file", file);
      formData.append("name", values?.name);
      formData.append("description", values?.description);
      formData.append("category", values?.category);
      formData.append("language", values?.language);
      formData.append("lives", values?.lives);
      formData.append("createdby", userInfoObject?._id);

      if (type === "update") {
        const response = await updateQuiz({ id, formData });
        if (response.message !== "success") {
          throw new Error(
            "Something went wrong. Please try again or contact administrator"
          );
        }
        message.success(" Quiz Updated in a Database");
      }

      if (type === "create") {
        const response = await postQuiz(formData);
        if (response.message !== "success") {
          throw new Error(
            "Something went wrong. Please try again or contact administrator"
          );
        }
        form.resetFields();
        message.success("New Quiz is added in a Database");
        categoryFromModel && callBack();
      }

      if (onSuccess) onSuccess();
      callGetCategoryList();
      setLoading(false);
    } catch (error) {
      console.log("error: ", error);
      notification.error({
        message: `${error?.status}: ${error?.statusText}`,
        description: error?.data?.message,
      });
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  function onChange(value) {
    console.log(`selected ${value}`);
  }

  function onSearch(val) {
    console.log("search:", val);
  }

  const onChangeFile = ({ fileList: newFileList }) => {
    setFile(newFileList?.[0].originFileObj);
  };

  function beforeUpload(file) {
    return false;
  }
  return (
    <div>
      <Spin spinning={formloading} size="large">
        <div className={styles.container}>
          <Form
            form={form}
            name="basic"
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Select Language"
              name="language"
              initialValue={language1}
            >
              <Select
                style={{
                  width: 120,
                }}
                onChange={handleChange}
              >
                <Select.Option value="Punjabi">Punjabi </Select.Option>
                <Select.Option value="English">English </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Quiz Name"
              name="name"
              initialValue={name}
              rules={[
                {
                  required: true,
                  message: "Please input Quiz Name",
                },
              ]}
            >
              <Input
                size="large"
                style={{ borderRadius: "5px" }}
                placeholder="Enter Quiz Name"
              />
            </Form.Item>
            <Form.Item
              label="Quiz Description"
              name="description"
              initialValue={description}
            >
              <TextArea placeholder="Quiz Description" rows={3} />
            </Form.Item>

            <Form.Item
              label="Select Category"
              name="category"
              initialValue={
                category?._id || (categoryFromModel && categoryFromModel?.id)
              }
              rules={[
                {
                  required: true,
                  message: "Please input Quiz Name",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select Category"
                optionFilterProp="children"
                onChange={onChange}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {data &&
                  data?.map((option) => (
                    <Select.Option key={option?._id} value={option?._id}>
                      {categoryColorScheme({
                        name: option?.name,
                        language: language1,
                      })}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Person Lives in Quiz"
              name="lives"
              initialValue={lives}
            >
              <Input
                size="large"
                style={{ borderRadius: "5px" }}
                placeholder="Enter Lives"
              />
            </Form.Item>

            <Form.Item name="files">
              <Upload
                accept="image/png, image/jpeg"
                onChange={onChangeFile}
                beforeUpload={beforeUpload}
                fileList={[]}
              >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 0,
                span: 24,
              }}
            >
              <Button loading={loading} type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Spin>
    </div>
  );
};

export default AddEditQuiz;
