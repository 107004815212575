import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  notification,
  message,
  Upload,
  Radio,
  Tooltip,
} from "antd";
import styles from "./styles.module.css";
import { UploadOutlined } from "@ant-design/icons";

import { getCategoryListForDropDownMenu } from "../../../services/categoryServices";
import {
  postQuestion,
  postQuestionImage,
} from "../../../services/questionServices";
import {
  categoryColorScheme,
  sortDropDownMenu,
} from "../../../components/SortAndColorDropDownMenu";

const AddNewQuestion = ({
  categoryFromModel,
  languageFromModel,
  comprehensionId,
  callBack,
}) => {
  // console.log(
  //   "Category from model",
  //   categoryFromModel,
  //   "Language from model",
  //   languageFromModel
  // );
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const { TextArea } = Input;
  const { Option } = Select;

  const [loading, setLoading] = useState(false);

  const [questionType, setQuestionType] = useState("text");
  const [answerType, setAnswerType] = useState("text");

  const [data, setData] = useState();

  const [language, setLanguage] = useState(
    languageFromModel && languageFromModel
  );
  const [question, setQuestion] = useState();
  const [option1, setOption1] = useState();
  const [option2, setOption2] = useState();
  const [option3, setOption3] = useState();
  const [option4, setOption4] = useState();
  const [optionsShow, setOptionsShow] = useState(true);

  const [form] = Form.useForm();
  useEffect(() => {
    callGetCategoryList();
    form.resetFields();
  }, [language]);

  const callGetCategoryList = async () => {
    const result = await getCategoryListForDropDownMenu({
      query: { language },
    });
    const sortedData = await result?.data?.sort(sortDropDownMenu);
    setData(sortedData);
    console.log("result", result);
  };

  const onFinish = async (values) => {
    console.log("values", values);
    try {
      const customPayload = {};
      customPayload.answer = values.answer;
      customPayload.category = values.category;
      if (values.explanation) customPayload.explanation = values.explanation;
      if (values.option1) customPayload.option1 = { text: values.option1 };
      if (values.option2) customPayload.option2 = { text: values.option2 };
      if (values.option3) customPayload.option3 = { text: values.option3 };
      if (values.option4) customPayload.option4 = { text: values.option4 };
      if (values.explanation) customPayload.explanation = values.explanation;
      if (comprehensionId) customPayload.comprehension = comprehensionId;

      if (option1) customPayload.option1 = option1;
      if (option2) customPayload.option2 = option2;
      if (option3) customPayload.option3 = option3;
      if (option4) customPayload.option4 = option4;

      customPayload.title = question ? question : { text: values.title };
      customPayload.type = values.type;
      customPayload.createdby = user._id;
      customPayload.language = values.language;

      setLoading(true);

      console.log("Form data", customPayload);
      const response = await postQuestion(customPayload);

      if (response.message !== "success") {
        throw new Error(
          "Something went wrong. Please try again or contact administrator"
        );
      }
      comprehensionId && callBack();
      message.success("New Question is added in a Database");
      form.resetFields();
      setQuestion(null);
      setOption1(null);
      setOption2(null);
      setOption3(null);
      setOption4(null);
      setQuestionType("text");
      setOptionsShow(true);
      setAnswerType("text");

      !categoryFromModel && window.location.reload(true);
      // above line window.location.reload(ture) this will reload page from server. we need this in this add question file
      categoryFromModel && callBack();
      setLoading(false);
    } catch (error) {
      notification.error({
        message: `${error?.status}: ${error?.statusText}`,
        description: error?.data?.message,
      });
      setLoading(false);
    }
  };

  const uploadImage = async ({ formData1, valueOf }) => {
    console.log("formData", formData1);
    console.log("Value off", valueOf);
    try {
      const response = await postQuestionImage(formData1);

      if (response.message !== "success") {
        throw new Error(
          "Something went wrong. Please try again or contact administrator"
        );
      }

      console.log("response image store:", response);
      if (valueOf === "question") {
        const questionPayload = {};
        questionPayload.text = response?.data?.media;
        questionPayload.mediaKey = response?.data?.mediaKey;
        questionPayload.type = "image";
        setQuestion(questionPayload);
      }
      if (valueOf === "option1") {
        const questionPayload = {};
        questionPayload.text = response?.data?.media;
        questionPayload.mediaKey = response?.data?.mediaKey;
        questionPayload.type = "image";
        setOption1(questionPayload);
      }
      if (valueOf === "option2") {
        const questionPayload = {};
        questionPayload.text = response?.data?.media;
        questionPayload.mediaKey = response?.data?.mediaKey;
        questionPayload.type = "image";
        setOption2(questionPayload);
      }
      if (valueOf === "option3") {
        const questionPayload = {};
        questionPayload.text = response?.data?.media;
        questionPayload.mediaKey = response?.data?.mediaKey;
        questionPayload.type = "image";
        setOption3(questionPayload);
      }
      if (valueOf === "option4") {
        const questionPayload = {};
        questionPayload.text = response?.data?.media;
        questionPayload.mediaKey = response?.data?.mediaKey;
        questionPayload.type = "image";
        setOption4(questionPayload);
      }
      message.success("Image Uploaded in Database");
    } catch (error) {
      notification.error({
        message: `${error?.status}: ${error?.statusText}`,
        description: error?.data?.message,
      });
    }
  };

  const onChangeFile = (val, valueOf) => {
    const { fileList: newFileList } = val;
    console.log("value of", valueOf);
    console.log("data in val:", val);
    console.log("newFileList:", newFileList);

    const formData1 = new FormData();
    formData1.append("image", newFileList?.[0].originFileObj);

    uploadImage({ formData1, valueOf });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  function handleChange(value) {
    console.log(`selected ${value}`);
    setLanguage(value);
  }

  function handleChangeQuestionType(value) {
    console.log(`selected ${value}`);
    setQuestionType(value);
  }
  function handleChangeAnswerType(value) {
    console.log(`selected ${value}`);
    setAnswerType(value);
  }

  console.log("state question", question);
  const showHide = (e) => {
    if (e.target.value === "options") {
      setOptionsShow(true);
    } else if (e.target.value === "boolean") {
      setOptionsShow(false);
    }
  };
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div>Add New Question</div>
        <div className={styles.form}>
          <Form
            form={form}
            name="basic"
            layout="horizontal"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Select Language"
              name="language"
              initialValue={language}
            >
              <Select
                style={{
                  width: 120,
                }}
                onChange={handleChange}
              >
                <Select.Option value="Punjabi">Punjabi </Select.Option>
                <Select.Option value="English">English </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="category"
              label="Category"
              initialValue={categoryFromModel && categoryFromModel}
              rules={[
                {
                  required: true,
                  message: "Please select Category First",
                },
              ]}
            >
              <Select
                placeholder="Select Category"
                size="large"
                style={{ borderRadius: "5px" }}
              >
                {data?.map((option) => (
                  <Option key={option?._id} value={option?._id}>
                    {/* {option?.name} */}
                    {categoryColorScheme({ name: option?.name, language })}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Question Type Image or Text"
              name="questionType"
              initialValue={questionType}
            >
              <Select
                style={{
                  width: 120,
                }}
                onChange={handleChangeQuestionType}
              >
                <Select.Option value="text">Text </Select.Option>
                <Select.Option value="image">Image </Select.Option>
              </Select>
            </Form.Item>
            {questionType === "text" ? (
              <Form.Item
                name="title"
                label="Question"
                rules={[
                  {
                    required: true,
                    message: "Please enter Question",
                  },
                ]}
              >
                <TextArea
                  size="large"
                  style={{ borderRadius: "5px" }}
                  placeholder="Please enter Question"
                  rows="4"
                />
              </Form.Item>
            ) : (
              <>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>Question</div>
                  <div style={{ marginLeft: 5 }}>
                    <Upload
                      accept="image/png, image/jpeg"
                      onChange={(val) => onChangeFile(val, "question")}
                      fileList={[]}
                    >
                      <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                  </div>
                  <div
                    style={{
                      marginLeft: 9,
                    }}
                  >
                    {question ? (
                      <img
                        src={question?.text}
                        alt="QuestionImage"
                        style={{
                          width: "150px",
                          height: "100px",
                          border: "solid",
                          borderwidth: "1px",
                          borderColor: "blue",
                          borderRadius: 7,
                        }}
                      />
                    ) : null}
                  </div>
                </div>
              </>
            )}
            <Form.Item
              label="Answer Type Image or Text"
              name="answerType"
              initialValue={answerType}
              style={{ marginTop: "10px" }}
            >
              <Select
                style={{
                  width: 120,
                }}
                onChange={handleChangeAnswerType}
              >
                <Select.Option value="text">Text </Select.Option>
                <Select.Option value="image">Image </Select.Option>
              </Select>
            </Form.Item>
            {answerType === "text" ? (
              <Form.Item
                name="type"
                label="Question Type"
                initialValue="options"
              >
                <Radio.Group onChange={showHide} initialValue="options">
                  <Tooltip
                    title="By Default selected for multiple choice questions"
                    placement="topLeft"
                  >
                    <Radio value="options">Options</Radio>
                  </Tooltip>
                  <Tooltip
                    title="Select this for True/False questions."
                    placement="topLeft"
                  >
                    <Radio value="boolean">True/False</Radio>
                  </Tooltip>
                </Radio.Group>
              </Form.Item>
            ) : (
              <Form.Item
                name="type"
                label="Question Type"
                initialValue="options"
              >
                <Radio.Group onChange={showHide} initialValue="options">
                  <Tooltip
                    title="By Default selected for multiple choice questions"
                    placement="topLeft"
                  >
                    <Radio value="options">Options</Radio>
                  </Tooltip>
                </Radio.Group>
              </Form.Item>
            )}
            {answerType === "text" ? (
              optionsShow ? (
                <>
                  <Form.Item
                    name="option1"
                    label="A"
                    rules={[
                      {
                        required: true,
                        message: "Please enter option",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      style={{ borderRadius: "5px" }}
                      placeholder="Please enter option A"
                    />
                  </Form.Item>
                  <Form.Item
                    name="option2"
                    label="B"
                    rules={[
                      {
                        required: true,
                        message: "Please enter option",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      style={{ borderRadius: "5px" }}
                      placeholder="Please enter option B"
                    />
                  </Form.Item>
                  <Form.Item
                    name="option3"
                    label="C"
                    rules={[
                      {
                        required: true,
                        message: "Please enter option",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      style={{ borderRadius: "5px" }}
                      placeholder="Please enter option C"
                    />
                  </Form.Item>
                  <Form.Item
                    name="option4"
                    label="D"
                    rules={[
                      {
                        required: true,
                        message: "Please enter option",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      style={{ borderRadius: "5px" }}
                      placeholder="Please enter option D"
                    />
                  </Form.Item>
                </>
              ) : (
                <>
                  <Form.Item
                    name="option1"
                    label="A"
                    rules={[
                      {
                        required: true,
                        message: "Please select option",
                      },
                    ]}
                  >
                    <Select size="large" style={{ borderRadius: "5px" }}>
                      <Option value="True">True</Option>
                      <Option value="False">False</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="option2"
                    label="B"
                    rules={[
                      {
                        required: true,
                        message: "Please select option",
                      },
                    ]}
                  >
                    <Select size="large" style={{ borderRadius: "5px" }}>
                      <Option value="True">True</Option>
                      <Option value="False">False</Option>
                    </Select>
                  </Form.Item>
                </>
              )
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 9,
                  }}
                >
                  <div>A</div>
                  <div style={{ marginLeft: 5 }}>
                    <Upload
                      accept="image/png, image/jpeg"
                      onChange={(val) => onChangeFile(val, "option1")}
                      fileList={[]}
                    >
                      <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                  </div>
                  <div
                    style={{
                      marginLeft: 9,
                    }}
                  >
                    {option1 ? (
                      <img
                        src={option1?.text}
                        alt="Answer1"
                        className={styles.answerImage}
                      />
                    ) : null}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 9,
                  }}
                >
                  <div>B</div>
                  <div style={{ marginLeft: 5 }}>
                    <Upload
                      accept="image/png, image/jpeg"
                      onChange={(val) => onChangeFile(val, "option2")}
                      fileList={[]}
                    >
                      <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                  </div>
                  <div
                    style={{
                      marginLeft: 9,
                    }}
                  >
                    {option2 ? (
                      <img
                        src={option2?.text}
                        alt="Answer2"
                        className={styles.answerImage}
                      />
                    ) : null}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 9,
                  }}
                >
                  <div>C</div>
                  <div style={{ marginLeft: 5 }}>
                    <Upload
                      accept="image/png, image/jpeg"
                      onChange={(val) => onChangeFile(val, "option3")}
                      fileList={[]}
                    >
                      <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                  </div>
                  <div
                    style={{
                      marginLeft: 9,
                    }}
                  >
                    {option3 ? (
                      <img
                        src={option3?.text}
                        alt="Answer3"
                        className={styles.answerImage}
                      />
                    ) : null}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 9,
                  }}
                >
                  <div>D</div>
                  <div style={{ marginLeft: 5 }}>
                    <Upload
                      accept="image/png, image/jpeg"
                      onChange={(val) => onChangeFile(val, "option4")}
                      fileList={[]}
                    >
                      <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                  </div>
                  <div
                    style={{
                      marginLeft: 9,
                    }}
                  >
                    {option4 ? (
                      <img
                        src={option4?.text}
                        alt="Answer4"
                        className={styles.answerImage}
                      />
                    ) : null}
                  </div>
                </div>
              </>
            )}
            {optionsShow ? (
              <Form.Item
                name="answer"
                label="Correct Answer"
                style={{ marginTop: "10px" }}
                rules={[
                  {
                    required: true,
                    message: "Please select correct answer",
                  },
                ]}
              >
                <Select
                  size="large"
                  style={{ borderRadius: "5px" }}
                  placeholder="Please select correct answer"
                >
                  <Option value="option1">A</Option>
                  <Option value="option2">B</Option>
                  <Option value="option3">C</Option>
                  <Option value="option4">D</Option>
                </Select>
              </Form.Item>
            ) : (
              <Form.Item
                name="answer"
                label="Correct Answer"
                style={{ marginTop: "10px" }}
                rules={[
                  {
                    required: true,
                    message: "Please select correct answer",
                  },
                ]}
              >
                <Select
                  size="large"
                  style={{ borderRadius: "5px" }}
                  placeholder="Please select correct answer"
                >
                  <Option value="option1">A</Option>
                  <Option value="option2">B</Option>
                </Select>
              </Form.Item>
            )}
            <Form.Item name="explanation" label="Explanations (Optional)">
              <TextArea
                style={{ borderRadius: "5px" }}
                placeholder="Enter Explanation if needed"
                rows="2"
              />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 4,
                span: 24,
              }}
            >
              <Button loading={loading} type="primary" htmlType="submit">
                Submit
              </Button>
              {comprehensionId && (
                <Button onClick={() => callBack()} style={{ marginLeft: 8 }}>
                  Close
                </Button>
              )}
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AddNewQuestion;
