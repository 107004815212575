//callApi is used to call api as given location be user in any path or page and get response according to request route.
//callApi also used to verify user. is it is a valid user or not. it get access token from local storage and verify it from server. it also verify access token time.if access token time is over it send refresh token to /me route to generate new access token if it expired. we give one day to access token. at the last we reverify access token at last stage.
import axios from "axios";

const hostName = "https://api.sondhwebcreators.com";
// const hostName = "http://localhost:8000";

const makeUrl = ({ url, query, pathParams }) => {
  let newUrl = url;
  if (query) {
    const keys = Object.keys(query);
    // [a, b, c, d]
    const values = Object.values(query);
    // [1, 2, 3, 4]
    let queryAddOn = "?";
    keys.map((key, index) => {
      queryAddOn += `${key}=${values[index]}&`;
    });
    newUrl += queryAddOn.slice(0, -1);
    //this line will remove & sign shown in path at the last. which is not needed at the end of the line
  }
  return newUrl;
};

const callApi = ({
  method,
  url,
  data = {},
  headers = {},
  query,
  pathParams,
}) => {
  // get access token
  const authorization = localStorage.getItem("accessToken");
  //get get accessToken from local stroage to send accessToken to server to used resources. only authorized person can access our resources
  const defaultHeaders = {
    authorization,
    "Content-type": "application/json",
    ...headers,
  };
  // console.log("method: ", method, "url: ", url);
  //callApi is universal api calling system we desing.we make data and headers empty by default.
  return new Promise((resolve, reject) => {
    axios({
      method,
      url: makeUrl({
        url: `${hostName}${url}`,
        query,
        pathParams,
      }),
      data,
      headers: defaultHeaders,
    })
      //method, url,data,headers is a pattern of axios. all are neccessory as same pattern.
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log("error: ", err);
        //err.response contain error as send by a server and we use error respnse for futher action taken.
        //  if err status is 401 and error message is "jwt expired"
        // then call refreshtokens API with refresh token in header
        // axios call to RT API .then().catch(error) clear local storage
        console.log("Error what we have", err.response);
        //error response status 401 means user is invalid or jwt is expired
        //server will send 401 when jwt expired. so we need to create new access token automaticaly using refresh token
        if (
          err.response.status === 401 &&
          err.response.data.message === "jwt expired"
        ) {
          const refreshToken = localStorage.getItem("refreshToken");
          //we took refresh token from local storage and send to server (path refresh to refresh) for verify refresh token. refresh token is going to send in headers. refresh path dont require any data so we send blank in data field
          console.log("refresh token", refreshToken);
          axios({
            method: "post",
            url: `${hostName}${"/api/common/auth/refresh"}`,
            data: {},
            headers: { token: refreshToken },
          }).then((res) => {
            console.log("response after represh token", res);
            console.log("what is in data", data);
            localStorage.setItem("accessToken", res.data.access_token);
            localStorage.setItem("refreshToken", res.data.refresh_token);
            //update local storage when we got access token and refresh token from server.
            console.log("url", url);
            //below axios call is going to use. this axios call help us to reverify what we done above. we use /me path to verify access token.
            //all access token verification must be true as above methods.
            axios({
              method: "post",
              url: `${hostName}${url}`,
              data,
              headers: { authorization: res.data.access_token },
            })
              .then((res) => {
                resolve(res.data);
                return;
                //resolve and return said that all work of this callapi is over here.
              })
              .catch((err) => {
                reject(err);
                //if user information mismatch or all above not work. we will remove accessToken, refreshToken, userinfo from local storage so that other user can login with their own details
              });
          });
        }

        reject(err.response);
      });
  });
};

export default callApi;
