import callApi from "../utils/callApi";

export const postQuiz = async (data) => {
  const result = await callApi({
    method: "post",
    url: "/api/admin/quiz",
    data: data,
  });
  // call api structure what is demanded is given below callApi(method, url, data, headers)
  return result;
};

export const updateQuiz = async ({ id, formData }) => {
  const result = await callApi({
    method: "put",
    url: `/api/admin/quiz/${id}`,
    data: formData,
  });
  // call api structure what is demanded is given below callApi(method, url, data, headers)
  return result;
};

export const getQuizList = async ({ query }) => {
  const result = await callApi({
    method: "get",
    url: "/api/admin/quiz",
    query,
  });
  // call api structure what is demanded is given below callApi(method, url, data, headers)
  return result;
};

export const deleteQuiz = async (id) => {
  const result = await callApi({
    method: "delete",
    url: `/api/admin/quiz/${id}`,
  });
  // call api structure what is demanded is given below callApi(method, url, data, headers)
  return result;
};

export const getSingleQuizDetail = async (id) => {
  const result = await callApi({
    method: "get",
    url: `/api/admin/quiz/${id}`,
  });
  // call api structure what is demanded is given below callApi(method, url, data, headers)
  return result;
};

export const getQuizQuestionList = async ({ id, query }) => {
  const result = await callApi({
    method: "get",
    url: `/api/admin/quiz/questions/${id}`,
    query,
  });
  // call api structure what is demanded is given below callApi(method, url, data, headers)
  return result;
};

export const addQuestionToQuiz = async ({ id, payload }) => {
  const result = await callApi({
    method: "put",
    url: `/api/admin/quiz/questions/${id}`,
    data: payload,
  });
  // call api structure what is demanded is given below callApi(method, url, data, headers)
  return result;
};

export const deleteQuestionFromQuiz = async ({ id, questionId }) => {
  console.log("quiz id", id);
  console.log("Quesiont id", questionId);
  // const payload = {
  //   questionId,
  // };
  // console.log("Quesiont id", payload);
  const result = await callApi({
    method: "delete",
    url: `/api/admin/quiz/questions/${id}`,
    data: questionId,
  });
  // call api structure what is demanded is given below callApi(method, url, data, headers)
  return result;
};

export const addComprehensionQuestionToQuizFunction = async ({
  id,
  payload,
}) => {
  console.log("ComprehensionId in api", payload, "Quiz ID api", id);

  const result = await callApi({
    method: "put",
    url: `/api/admin/quiz/comprehensionquestion/${id}`,
    data: payload,
  });
  // call api structure what is demanded is given below callApi(method, url, data, headers)
  return result;
};

export const setQuizOnlineOffline = async ({ id, payload }) => {
  const result = await callApi({
    method: "put",
    url: `/api/admin/quiz/onlineoffline/${id}`,
    data: payload,
  });
  return result;
};

export const updateSortedQuestionToQuiz = async ({ id, payload }) => {
  const result = await callApi({
    method: "put",
    url: `/api/admin/quiz/updatesortedquestiontoquiz/${id}`,
    data: payload,
  });
  // call api structure what is demanded is given below callApi(method, url, data, headers)
  return result;
};

export const updateCustomIdQuizService = async ({ id, values }) => {
  console.log("id", id, "customID", values);
  const result = await callApi({
    method: "put",
    url: `/api/admin/quiz/updatequizid/${id}`,
    data: values,
  });

  // call api structure what is demanded is given below callApi(method, url, data, headers)
  return result;
};
