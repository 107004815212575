import callApi from "../utils/callApi";

export const postPoll = async (payload) => {
  const result = await callApi({
    method: "post",
    url: "/api/admin/poll",
    data: payload,
  });
  // call api structure what is demanded is given below callApi(method, url, data, headers)
  return result;
};
