import {
  Button,
  message,
  Modal,
  notification,
  Pagination,
  Popconfirm,
  Space,
  Spin,
  Table,
  Tooltip,
} from "antd";
import React, { useState, useEffect } from "react";
import { getComprehensionList } from "../../../../services/comprehensionServices";
import { addComprehensionQuestionToQuizFunction } from "../../../../services/quizServices";
import ViewComprehensionAndQuestions from "../ViewComprehensionAndQuestions";

const AddComprehensionQuestionToQuiz = ({ id, callBack }) => {
  const [comprehensionList, setComprehensionList] = useState({
    data: [],
    totalRecords: 0,
  });

  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const [viewModelVisible, setViewModelVisible] = useState(false);
  const [compId, setCompId] = useState();

  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(10);
  useEffect(() => {
    callGetComprehensionList();
  }, [start, limit]);

  const callGetComprehensionList = async () => {
    try {
      setLoading(true);
      const result = await getComprehensionList({
        query: { start, limit },
      });
      setLoading(false);
      setComprehensionList(result);
      console.log(comprehensionList);
      console.log("Result", result);
    } catch (error) {
      console.log("error: ", error);
      notification.error({
        message: `${error?.status}: ${error?.statusText}`,
        description: error?.data?.message,
      });
      // setLoading(false);
    }
  };

  const callGetSingleComprehensionDetail = (id) => {
    setCompId(id);
    setViewModelVisible(true);
  };

  const callAddComprehensionQuestionToQuizFunction = async (
    comprehensionId
  ) => {
    try {
      console.log("ComprehensionId", comprehensionId, "Quiz ID", id);
      setButtonLoading(true);

      const response = await addComprehensionQuestionToQuizFunction({
        id,
        payload: { comprehensionId },
      });
      // setLoading(false);
      // console.log(comprehensionList);
      if (response.message !== "success") {
        throw new Error(
          "Something went wrong. Please try again or contact administrator"
        );
      }
      message.success("Comprehension Questoins Added in a Quiz", 5);

      setButtonLoading(false);
    } catch (error) {
      console.log("error: ", error);
      notification.error({
        message: `${error?.status}: ${error?.statusText}`,
        description: error?.data?.message,
      });
      // setLoading(false);
    }
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "slug",
      key: "slug",
    },
    {
      title: "Comprehension",
      dataIndex: "comprehension",
      key: "_id",

      render: (value, record) => <div>{value + ". . . ."}</div>,
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "_id",
      render: (value, record) => record?.category?.name,
    },
    {
      title: "Total Question",
      dataIndex: "questionCount",
      key: "_id",
      render: (value, record) => record?.questionCount,
    },
    {
      title: "Created by",
      dataIndex: "createdBy",
      key: "_id",
      render: (value, record) => record?.createdBy?.name,
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Tooltip
            placement="topRight"
            title="View all questions belong to this comprehension."
          >
            <Button
              onClick={() => {
                callGetSingleComprehensionDetail(record?._id);
              }}
            >
              View
            </Button>
          </Tooltip>

          <Popconfirm
            title="Are you sure, you want to add all comprehension question to quiz?"
            onConfirm={() =>
              callAddComprehensionQuestionToQuizFunction(record?._id)
            }
            okText="Confirm"
            cancelText="Cancel"
            placement="leftTop"
            okButtonProps={{
              danger: true,
            }}
          >
            <Tooltip
              placement="topRight"
              title="This will add all questions of comprehension to quiz. Please click View to see all questions first."
            >
              <Button type="primary" loading={buttonLoading}>
                Add Question in Quiz
              </Button>
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div>Comprehension Table </div>
      <Spin spinning={loading} size="large">
        <div>
          <>
            <Table
              columns={columns}
              dataSource={comprehensionList?.data}
              pagination={false}
            />
            <Pagination
              total={comprehensionList?.totalRecords}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              pageSize={limit}
              current={start / limit + 1}
              onChange={(page, pageSize) => {
                setStart((page - 1) * limit);
              }}
            />
          </>
        </div>
        <div>
          <Button onClick={() => callBack()}>Return</Button>
        </div>
      </Spin>

      {/* Comprehension View Model Start */}
      <div>
        <>
          <Modal
            visible={viewModelVisible}
            title="View Comprehension"
            // onOk={()=>handleOk()}
            onCancel={() => {
              setCompId("");
              setTimeout(() => {
                setViewModelVisible(false);
              }, 100);
            }}
            width={"90%"}
            footer={[
              <Button
                key="back"
                onClick={() => {
                  setCompId("");
                  setTimeout(() => {
                    setViewModelVisible(false);
                  }, 100);
                  callGetComprehensionList();
                }}
              >
                Return
              </Button>,
            ]}
          >
            <ViewComprehensionAndQuestions id={compId} />
          </Modal>
        </>
      </div>
      {/* Comprehension View Model Start */}
    </div>
  );
};

export default AddComprehensionQuestionToQuiz;
