import callApi from "../utils/callApi";

export const postComprehension = async (data) => {
  const result = await callApi({
    method: "post",
    url: "/api/admin/comprehension",
    data: data,
  });
  // call api structure what is demanded is given below callApi(method, url, data, headers)
  return result;
};

export const getComprehensionList = async ({ query }) => {
  const result = await callApi({
    method: "get",
    url: "/api/admin/comprehension",
    query,
  });
  // call api structure what is demanded is given below callApi(method, url, data, headers)
  return result;
};

export const deleteComprehension = async (id) => {
  const result = await callApi({
    method: "delete",
    url: `/api/admin/comprehension/${id}`,
  });
  // call api structure what is demanded is given below callApi(method, url, data, headers)
  return result;
};

export const getSingleComprehensionDetail = async (id) => {
  const result = await callApi({
    method: "get",
    url: `/api/admin/comprehension/${id}`,
  });
  // call api structure what is demanded is given below callApi(method, url, data, headers)
  return result;
};

export const updateComprehension = async ({ id, payload }) => {
  const result = await callApi({
    method: "put",
    url: `/api/admin/comprehension/${id}`,
    data: payload,
  });
  // call api structure what is demanded is given below callApi(method, url, data, headers)
  return result;
};
