import React, { useEffect, useState } from "react";
import {
  Table,
  Space,
  Button,
  Pagination,
  notification,
  Tooltip,
  Popconfirm,
  message,
} from "antd";
import styles from "./styles.module.css";
import {
  getCategoryList,
  setCategoryOnlineOffline,
} from "../../../services/categoryServices";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

const CategoryList = () => {
  const [list, setList] = useState({ data: [], totalRecords: 0 });
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(10);
  //create loading state to show loading
  useEffect(() => {
    callGetCategoryList();
  }, [start, limit]);

  const callGetCategoryList = async () => {
    try {
      setLoading(true);
      const result = await getCategoryList({
        query: { start, limit, type: "boolean" },
      });
      setLoading(false);
      setList(result);
      // console.log(result);
    } catch (error) {
      console.log("error: ", error);
      notification.error({
        message: `${error?.status}: ${error?.statusText}`,
        description: error?.data?.message,
      });
      // setLoading(false);
    }
    //
  };

  const callSetCategoryOnlineOffline = async (record) => {
    try {
      // setLoading(true);

      console.log("grant revoke record", record);
      const payload = {
        is_active: record?.is_active,
      };
      console.log("grant revoke payload", payload);
      const response = await setCategoryOnlineOffline({
        id: record?.id,
        payload,
      });
      if (response?.message === "success")
        message.success("Quiz Online Status Changed");
      else message.success("Something went Wrong");

      callGetCategoryList();
    } catch (error) {
      notification.error({
        message: `${error?.status}: ${error?.statusText}`,
        description: error?.data?.message,
      });
    }
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "media",
      key: "media",
      render: (value, record) => (
        <Link to={`/categorylist/${record._id}`}>
          <img
            src={
              value
                ? value
                : "https://quiz-app1.s3.ap-south-1.amazonaws.com/media/images/Offline.jpg"
            }
            alt="Login"
            className={styles.image}
          />
        </Link>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (value, record) => (
        <Link to={`/categorylist/${record._id}`}>{value}</Link>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Language",
      dataIndex: "language",
      key: "language",
      filters: [
        {
          text: "English",
          value: "English",
        },
        {
          text: "Punjabi",
          value: "Punjabi",
        },
      ],
      onFilter: (value, record) => record?.language.indexOf(value) === 0,
    },
    {
      title: "Parent",
      dataIndex: "parent",
      key: "parent",
      render: (data) => (data != null ? data?.name : "Main Category"),
      filterMultiple: false,
      filters: [
        {
          text: "Sub Category",
          value: "6",
        },
        {
          text: "Parent Category",
          value: "Main",
        },
      ],
      onFilter: (value, record) => {
        if (value === "Main") {
          if (record?.parent?.name) return false;
          return true;
        } else if (value === "6") {
          if (record?.parent?.name) return true;
          return false;
        }
      },
      filterSearch: true,
      // width: "40%",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data) => dayjs(data).format("DD-MM-YYYY h:mm"),
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          {record?.is_active === false ? (
            <Popconfirm
              title="Are you sure to make it Online"
              onConfirm={() =>
                callSetCategoryOnlineOffline({
                  id: record?._id,
                  is_active: true,
                })
              }
              okText="Confirm"
              cancelText="Cancel"
              placement="leftTop"
              okButtonProps={{
                danger: true,
              }}
            >
              <Tooltip
                placement="topLeft"
                title="This will diplay Category to all users"
              >
                <Button type="danger" className={styles.button}>
                  Offline
                </Button>
              </Tooltip>
            </Popconfirm>
          ) : (
            <Tooltip
              placement="topLeft"
              title="This will hide Category from all users"
            >
              <Button
                className={styles.button}
                style={{
                  backgroundColor: "green",
                  color: "white",
                  fontWeight: "normal",
                }}
                onClick={() =>
                  callSetCategoryOnlineOffline({
                    id: record?._id,
                    is_active: false,
                  })
                }
              >
                Online
              </Button>
            </Tooltip>
          )}
          <Link to={`/categorylist/${record._id}`}>
            <Tooltip
              placement="topLeft"
              title="Category detail with Subcategory list"
            >
              <Button type="primary">Detail View</Button>
            </Tooltip>
          </Link>
        </Space>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }
  const onShowSizeChange = (current, pageSize) => {
    setLimit(pageSize);
    // console.log(current, pageSize);
  };
  return (
    <div className={styles.main}>
      {/* <div className={styles.headerContainer}>
        <h1 className={styles.headerText}>Category List </h1>
      </div> */}
      <div className={styles.container}>
        <Table
          loading={loading}
          columns={columns}
          dataSource={list?.data}
          onChange={onChange}
          pagination={false}
          size="middle"
          rowKey={(record) => record?._id}
        />
        <Pagination
          total={list?.totalRecords}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          showSizeChanger
          pageSize={limit}
          onShowSizeChange={onShowSizeChange}
          current={start / limit + 1}
          onChange={(page, pageSize) => {
            setStart((page - 1) * limit);
          }}
        />
      </div>
    </div>
  );
};

export default CategoryList;
