import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  notification,
  message,
  Upload,
  Radio,
  Tooltip,
  Spin,
} from "antd";
import styles from "./styles.module.css";
import { UploadOutlined } from "@ant-design/icons";

import { getCategoryListForDropDownMenu } from "../../../../services/categoryServices";
import {
  getSingleQuestion,
  postQuestionImage,
  updateQuestion,
} from "../../../../services/questionServices";
import {
  categoryColorScheme,
  sortDropDownMenu,
} from "../../../../components/SortAndColorDropDownMenu";

const AddUpdateQuestionComponent = ({ id, callBack }) => {
  const { TextArea } = Input;
  const user = JSON.parse(localStorage.getItem("userInfo"));

  const { Option } = Select;

  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  const [questionType, setQuestionType] = useState("text");
  const [answerType, setAnswerType] = useState("text");

  const [data, setData] = useState();

  const [language, setLanguage] = useState("");
  const [question, setQuestion] = useState();
  const [option1, setOption1] = useState();
  const [option2, setOption2] = useState();
  const [option3, setOption3] = useState();
  const [option4, setOption4] = useState();
  const [optionsShow, setOptionsShow] = useState(true);
  const [category, setCategory] = useState();

  const [form] = Form.useForm();
  useEffect(() => {
    {
      language !== "" && callGetCategoryList();
    }
  }, [language]);

  useEffect(() => {
    getSingleQuestionFunction();
  }, [id]);

  const callGetCategoryList = async () => {
    console.log("category", category);
    console.log("language", language);
    setPageLoading(true);
    const result = await getCategoryListForDropDownMenu({
      query: { language },
    });
    const sortedData = await result?.data?.sort(sortDropDownMenu);
    setData(sortedData);

    // setData(result?.data);
    console.log("result", result);
    setPageLoading(false);
  };

  const resetFieldsFuntion = () => {
    form.resetFields();
    setQuestionType("text");
    setAnswerType("text");
    setData(null);
    setLanguage("");
    setQuestion("");
    setOption1("");
    setOption2("");
    setOption3("");
    setOption4("");
    setOptionsShow(true);
    setCategory("");
  };
  const closeAndReset = () => {
    resetFieldsFuntion();
    setPageLoading(true);
    setTimeout(() => {
      callBack();
      setPageLoading(false);
    }, 10);
  };
  const onFinish = async (values) => {
    console.log("values", values);
    try {
      const customPayload = {};
      customPayload.answer = values.answer;
      customPayload.category = values.category;
      if (values?.explanation) customPayload.explanation = values.explanation;
      if (values?.option1) customPayload.option1 = { text: values.option1 };
      if (values?.option2) customPayload.option2 = { text: values.option2 };
      if (values?.option3) customPayload.option3 = { text: values.option3 };
      if (values?.option4) customPayload.option4 = { text: values.option4 };
      if (values?.explanation) customPayload.explanation = values?.explanation;

      if (option1) customPayload.option1 = option1;
      if (option2) customPayload.option2 = option2;
      if (option3) customPayload.option3 = option3;
      if (option4) customPayload.option4 = option4;

      customPayload.title = question ? question : { text: values.title };
      customPayload.type = values.type;
      customPayload.createdby = user._id;
      customPayload.language = values.language;

      setLoading(true);

      console.log("Form data", customPayload);
      const response = await updateQuestion({ id, customPayload });

      if (response.message !== "success") {
        throw new Error(
          "Something went wrong. Please try again or contact administrator"
        );
      }

      message.success("Question Successfuly Updated");

      closeAndReset();
      setLoading(false);
    } catch (error) {
      notification.error({
        message: `${error?.status}: ${error?.statusText}`,
        description: error?.data?.message,
      });
      setLoading(false);
    }
  };

  const getSingleQuestionFunction = async () => {
    try {
      setPageLoading(true);
      const singleQuestion = await getSingleQuestion(id);

      console.log("Single Question Data:", singleQuestion);

      if (singleQuestion?.message === "success") {
        setLanguage(singleQuestion?.data?.language);
        setCategory(singleQuestion?.data?.category);
        form.setFieldsValue({
          answer: singleQuestion?.data?.answer,
          explanation: singleQuestion?.data?.explanation,
          category: singleQuestion?.data?.category,
          language: singleQuestion?.data?.language,
        });
        if (singleQuestion?.data?.type === "boolean") {
          setOptionsShow(false);
          form.setFieldsValue({
            type: singleQuestion?.data?.type,
          });
        }
        if (singleQuestion?.data?.title?.type === "text")
          form.setFieldsValue({
            title: singleQuestion?.data?.title?.text,
            questionType: singleQuestion?.data?.title?.type,
          });
        else {
          setQuestion(singleQuestion?.data?.title);
          setQuestionType(singleQuestion?.data?.title?.type);
          form.setFieldsValue({
            questionType: singleQuestion?.data?.title?.type,
          });
        }
        //option1
        if (singleQuestion?.data?.option1?.type === "text") {
          setAnswerType(singleQuestion?.data?.option1?.type);
          form.setFieldsValue({
            option1: singleQuestion?.data?.option1?.text,
            answerType: singleQuestion?.data?.option1?.type,
          });
        } else {
          setOption1(singleQuestion?.data?.option1);
          setAnswerType("Image");
          form.setFieldsValue({ answerType: "Image" });
        }

        if (singleQuestion?.data?.option2?.type === "text") {
          form.setFieldsValue({
            option2: singleQuestion?.data?.option2?.text,
          });
        } else {
          setOption2(singleQuestion?.data?.option2);
        }
        if (singleQuestion?.data?.option3?.type === "text") {
          form.setFieldsValue({
            option3: singleQuestion?.data?.option3?.text,
          });
        } else {
          setOption3(singleQuestion?.data?.option3);
        }
        if (singleQuestion?.data?.option4?.type === "text") {
          form.setFieldsValue({
            option4: singleQuestion?.data?.option4?.text,
          });
        } else {
          setOption4(singleQuestion?.data?.option4);
        }

        setPageLoading(false);
      }
    } catch (error) {
      notification.error({
        message: `${error?.status}: ${error?.statusText}`,
        description: error?.data?.message,
      });
      setPageLoading(false);
    }
  };

  const uploadImage = async ({ formData1, valueOf }) => {
    console.log("formData", formData1);
    console.log("Value off", valueOf);
    try {
      const response = await postQuestionImage(formData1);

      if (response.message !== "success") {
        throw new Error(
          "Something went wrong. Please try again or contact administrator"
        );
      }

      console.log("response image store:", response);
      if (valueOf === "question") {
        const questionPayload = {};
        questionPayload.text = response?.data?.media;
        questionPayload.mediaKey = response?.data?.mediaKey;
        questionPayload.type = "image";
        setQuestion(questionPayload);
      }
      if (valueOf === "option1") {
        const questionPayload = {};
        questionPayload.text = response?.data?.media;
        questionPayload.mediaKey = response?.data?.mediaKey;
        questionPayload.type = "image";
        setOption1(questionPayload);
      }
      if (valueOf === "option2") {
        const questionPayload = {};
        questionPayload.text = response?.data?.media;
        questionPayload.mediaKey = response?.data?.mediaKey;
        questionPayload.type = "image";
        setOption2(questionPayload);
      }
      if (valueOf === "option3") {
        const questionPayload = {};
        questionPayload.text = response?.data?.media;
        questionPayload.mediaKey = response?.data?.mediaKey;
        questionPayload.type = "image";
        setOption3(questionPayload);
      }
      if (valueOf === "option4") {
        const questionPayload = {};
        questionPayload.text = response?.data?.media;
        questionPayload.mediaKey = response?.data?.mediaKey;
        questionPayload.type = "image";
        setOption4(questionPayload);
      }
      message.success("Image Uploaded in Database");
    } catch (error) {
      notification.error({
        message: `${error?.status}: ${error?.statusText}`,
        description: error?.data?.message,
      });
    }
  };

  const onChangeFile = (val, valueOf) => {
    const { fileList: newFileList } = val;
    console.log("value of", valueOf);
    console.log("data in val:", val);
    console.log("newFileList:", newFileList);

    const formData1 = new FormData();
    formData1.append("image", newFileList?.[0].originFileObj);

    uploadImage({ formData1, valueOf });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  function onChange(value) {
    console.log(`selected ${value}`);
  }
  function handleChange(value) {
    console.log(`selected ${value}`);
    setLanguage(value);
  }

  function handleChangeQuestionType(value) {
    console.log(`selected ${value}`);
    setQuestionType(value);
  }
  function handleChangeAnswerType(value) {
    console.log(`selected ${value}`);
    setAnswerType(value);
  }
  function onSearch(val) {
    console.log("search:", val);
  }

  const showHide = (e) => {
    if (e.target.value === "options") {
      setOptionsShow(true);
    } else if (e.target.value === "boolean") {
      setOptionsShow(false);
    }
  };
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div>Update Question</div>
        <Spin size="large" spinning={pageLoading}>
          <div className={styles.form}>
            <Form
              form={form}
              name="basic"
              layout="horizontal"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Select Language"
                name="language"
                initialValue={language}
              >
                <Select
                  style={{
                    width: 120,
                  }}
                  onChange={handleChange}
                >
                  <Select.Option value="Punjabi">Punjabi </Select.Option>
                  <Select.Option value="English">English </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="category"
                label="Category"
                initialValue={category}
                rules={[
                  {
                    required: true,
                    message: "Please select Category First",
                  },
                ]}
              >
                <Select
                  placeholder="Select Category"
                  size="large"
                  style={{ borderRadius: "5px" }}
                >
                  {data &&
                    data?.map((option) => (
                      <Option key={option?._id} value={option?._id}>
                        {categoryColorScheme({ name: option?.name, language })}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Question Type Image or Text"
                name="questionType"
                initialValue={questionType}
              >
                <Select
                  style={{
                    width: 120,
                  }}
                  onChange={handleChangeQuestionType}
                >
                  <Select.Option value="text">Text </Select.Option>
                  <Select.Option value="image">Image </Select.Option>
                </Select>
              </Form.Item>
              {questionType === "text" ? (
                <Form.Item
                  name="title"
                  label="Question"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Question",
                    },
                  ]}
                >
                  <TextArea
                    size="large"
                    style={{ borderRadius: "5px" }}
                    placeholder="Please enter Question"
                    rows="4"
                  />
                </Form.Item>
              ) : (
                <>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div>Question</div>
                    <div style={{ marginLeft: 5 }}>
                      <Upload
                        accept="image/png, image/jpeg"
                        onChange={(val) => onChangeFile(val, "question")}
                        fileList={[]}
                      >
                        <Button icon={<UploadOutlined />}>
                          Click to Change
                        </Button>
                      </Upload>
                    </div>
                    <div
                      style={{
                        marginLeft: 9,
                      }}
                    >
                      {question ? (
                        <img
                          src={question?.text}
                          alt="QuestionImage"
                          style={{
                            width: "150px",
                            height: "100px",
                            border: "solid",
                            borderwidth: "1px",
                            borderColor: "blue",
                            borderRadius: 7,
                          }}
                        />
                      ) : null}
                    </div>
                  </div>
                </>
              )}
              <Form.Item
                label="Answer Type Image or Text"
                name="answerType"
                initialValue={answerType}
                style={{ marginTop: "10px" }}
              >
                <Select
                  style={{
                    width: 120,
                  }}
                  onChange={handleChangeAnswerType}
                >
                  <Select.Option value="text">Text </Select.Option>
                  <Select.Option value="image">Image </Select.Option>
                </Select>
              </Form.Item>
              {answerType === "text" ? (
                <Form.Item
                  name="type"
                  label="Answer Type"
                  initialValue="options"
                >
                  <Radio.Group
                    onChange={showHide}
                    name="radio"
                    initialValue="options"
                  >
                    <Tooltip
                      title="By Default selected for multiple choice questions"
                      placement="topLeft"
                    >
                      <Radio value="options">Options</Radio>
                    </Tooltip>
                    <Tooltip
                      title="Select this for True/False questions."
                      placement="topLeft"
                    >
                      <Radio value="boolean">True/False</Radio>
                    </Tooltip>
                  </Radio.Group>
                </Form.Item>
              ) : (
                <Form.Item
                  name="type"
                  label="Question Type"
                  initialValue="options"
                >
                  <Radio.Group onChange={showHide} initialValue="options">
                    <Tooltip
                      title="By Default selected for multiple choice questions"
                      placement="topLeft"
                    >
                      <Radio value="options">Options</Radio>
                    </Tooltip>
                  </Radio.Group>
                </Form.Item>
              )}
              {answerType === "text" ? (
                optionsShow ? (
                  <>
                    <Form.Item
                      name="option1"
                      label="A"
                      rules={[
                        {
                          required: true,
                          message: "Please enter option",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        style={{ borderRadius: "5px" }}
                        placeholder="Please enter option A"
                      />
                    </Form.Item>
                    <Form.Item
                      name="option2"
                      label="B"
                      rules={[
                        {
                          required: true,
                          message: "Please enter option",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        style={{ borderRadius: "5px" }}
                        placeholder="Please enter option B"
                      />
                    </Form.Item>
                    <Form.Item
                      name="option3"
                      label="C"
                      rules={[
                        {
                          required: true,
                          message: "Please enter option",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        style={{ borderRadius: "5px" }}
                        placeholder="Please enter option C"
                      />
                    </Form.Item>
                    <Form.Item
                      name="option4"
                      label="D"
                      rules={[
                        {
                          required: true,
                          message: "Please enter option",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        style={{ borderRadius: "5px" }}
                        placeholder="Please enter option D"
                      />
                    </Form.Item>
                  </>
                ) : (
                  <>
                    <Form.Item
                      name="option1"
                      label="A"
                      rules={[
                        {
                          required: true,
                          message: "Please select option",
                        },
                      ]}
                    >
                      <Select size="large" style={{ borderRadius: "5px" }}>
                        <Option value="True">True</Option>
                        <Option value="False">False</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="option2"
                      label="B"
                      rules={[
                        {
                          required: true,
                          message: "Please select option",
                        },
                      ]}
                    >
                      <Select size="large" style={{ borderRadius: "5px" }}>
                        <Option value="True">True</Option>
                        <Option value="False">False</Option>
                      </Select>
                    </Form.Item>
                  </>
                )
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: 9,
                    }}
                  >
                    <div>A</div>
                    <div style={{ marginLeft: 5 }}>
                      <Upload
                        accept="image/png, image/jpeg"
                        onChange={(val) => onChangeFile(val, "option1")}
                        fileList={[]}
                      >
                        <Button icon={<UploadOutlined />}>
                          Click to Upload
                        </Button>
                      </Upload>
                    </div>
                    <div
                      style={{
                        marginLeft: 9,
                      }}
                    >
                      {option1 ? (
                        <img
                          src={option1?.text}
                          alt="Answer1"
                          className={styles.answerImage}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: 9,
                    }}
                  >
                    <div>B</div>
                    <div style={{ marginLeft: 5 }}>
                      <Upload
                        accept="image/png, image/jpeg"
                        onChange={(val) => onChangeFile(val, "option2")}
                        fileList={[]}
                      >
                        <Button icon={<UploadOutlined />}>
                          Click to Upload
                        </Button>
                      </Upload>
                    </div>
                    <div
                      style={{
                        marginLeft: 9,
                      }}
                    >
                      {option2 ? (
                        <img
                          src={option2?.text}
                          alt="Answer2"
                          className={styles.answerImage}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: 9,
                    }}
                  >
                    <div>C</div>
                    <div style={{ marginLeft: 5 }}>
                      <Upload
                        accept="image/png, image/jpeg"
                        onChange={(val) => onChangeFile(val, "option3")}
                        fileList={[]}
                      >
                        <Button icon={<UploadOutlined />}>
                          Click to Upload
                        </Button>
                      </Upload>
                    </div>
                    <div
                      style={{
                        marginLeft: 9,
                      }}
                    >
                      {option3 ? (
                        <img
                          src={option3?.text}
                          alt="Answer3"
                          className={styles.answerImage}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: 9,
                    }}
                  >
                    <div>D</div>
                    <div style={{ marginLeft: 5 }}>
                      <Upload
                        accept="image/png, image/jpeg"
                        onChange={(val) => onChangeFile(val, "option4")}
                        fileList={[]}
                      >
                        <Button icon={<UploadOutlined />}>
                          Click to Upload
                        </Button>
                      </Upload>
                    </div>
                    <div
                      style={{
                        marginLeft: 9,
                      }}
                    >
                      {option4 ? (
                        <img
                          src={option4?.text}
                          alt="Answer4"
                          className={styles.answerImage}
                        />
                      ) : null}
                    </div>
                  </div>
                </>
              )}
              {optionsShow ? (
                <Form.Item
                  name="answer"
                  label="Correct Answer"
                  style={{ marginTop: "10px" }}
                  rules={[
                    {
                      required: true,
                      message: "Please select correct answer",
                    },
                  ]}
                >
                  <Select
                    size="large"
                    style={{ borderRadius: "5px" }}
                    placeholder="Please select correct answer"
                  >
                    <Option value="option1">A</Option>
                    <Option value="option2">B</Option>
                    <Option value="option3">C</Option>
                    <Option value="option4">D</Option>
                  </Select>
                </Form.Item>
              ) : (
                <Form.Item
                  name="answer"
                  label="Correct Answer"
                  style={{ marginTop: "10px" }}
                  rules={[
                    {
                      required: true,
                      message: "Please select correct answer",
                    },
                  ]}
                >
                  <Select
                    size="large"
                    style={{ borderRadius: "5px" }}
                    placeholder="Please select correct answer"
                  >
                    <Option value="option1">A</Option>
                    <Option value="option2">B</Option>
                  </Select>
                </Form.Item>
              )}
              <Form.Item name="explanation" label="Explanations (Optional)">
                <TextArea
                  style={{ borderRadius: "5px" }}
                  placeholder="Enter Explanation if needed"
                  rows="2"
                />
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 24,
                }}
              >
                <Button loading={loading} type="primary" htmlType="submit">
                  Submit
                </Button>
                <Button
                  onClick={() => closeAndReset()}
                  style={{ marginLeft: "9px" }}
                >
                  Close
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Spin>
      </div>
    </div>
  );
};

export default AddUpdateQuestionComponent;
