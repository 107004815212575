import callApi from "../utils/callApi";

export const getCategoryList = async ({ query = {} }) => {
  console.log("query in category", query);
  const result = await callApi({
    method: "get",
    url: "/api/admin/category",
    query,
  });
  // call api structure what is demanded is given below callApi(method, url, data, headers)
  return result;
};

export const getCategoryListForDropDownMenu = async ({ query }) => {
  const result = await callApi({
    method: "get",
    url: "/api/admin/category/categorydropdown",
    query,
  });
  // call api structure what is demanded is given below callApi(method, url, data, headers)
  return result;
};

export const postCategory = async (data) => {
  const headers = { "Content-type": "application/json" };

  const result = await callApi({
    method: "post",
    url: "/api/admin/category",
    data: data,
    headers: headers,
  });
  // call api structure what is demanded is given below callApi(method, url, data, headers)
  return result;
};

export const postComprehensionImage = async (payload) => {
  const result = await callApi({
    method: "post",
    url: "/api/admin/comprehension/uploadcomprehensionimage",
    data: payload,
  });
  // call api structure what is demanded is given below callApi(method, url, data, headers)
  return result;
};

// export const getMainCategoryList = async ({ query = {} }) => {
//   const headers = { "Content-type": "application/json" };

//   const result = await callApi({
//     method: "get",
//     query: query,
//     url: "/api/category",
//     data: null,
//     headers: headers,
//   });
//   // call api structure what is demanded is given below callApi(method, url, data, headers)
//   return result;
// };

export const getSubCategoryList = async (id) => {
  const headers = { "Content-type": "application/json" };

  const result = await callApi({
    method: "get",
    url: `/api/admin/category/${id}/subcategory`,
    data: null,
    headers: headers,
  });
  // call api structure what is demanded is given below callApi(method, url, data, headers)
  return result;
};

export const getSingleCategory = async (id) => {
  const headers = { "Content-type": "application/json" };

  const result = await callApi({
    method: "get",
    url: `/api/admin/category/${id}`,
    data: null,
    headers: headers,
  });
  // call api structure what is demanded is given below callApi(method, url, data, headers)
  return result;
};

export const deleteCategory = async (id) => {
  const headers = {
    "Content-type": "application/json",
  };

  const result = await callApi({
    method: "delete",
    url: `/api/admin/category/${id}`,

    headers: headers,
  });

  // call api structure what is demanded is given below callApi(method, url, data, headers)
  return result;
};

export const updateCategory = async ({ id, payload }) => {
  const headers = {
    "Content-type": "application/json",
  };

  const result = await callApi({
    method: "put",
    url: `/api/admin/category/${id}`,
    data: payload,
    headers: headers,
  });

  // call api structure what is demanded is given below callApi(method, url, data, headers)
  return result;
};

export const setCategoryOnlineOffline = async ({ id, payload }) => {
  const result = await callApi({
    method: "put",
    url: `/api/admin/category/onlineoffline/${id}`,
    data: payload,
  });
  return result;
};

export const updateCustomIdService = async ({ id, values }) => {
  console.log("id", id, "customID", values);
  const result = await callApi({
    method: "put",
    url: `/api/admin/category/updatecustomid/${id}`,
    data: values,
  });

  // call api structure what is demanded is given below callApi(method, url, data, headers)
  return result;
};
