import {
  Form,
  Input,
  Button,
  notification,
  Space,
  Table,
  Modal,
  message,
  Popconfirm,
  Tooltip,
  Pagination,
} from "antd";
import React, { useEffect, useState } from "react";
import { PoweroffOutlined } from "@ant-design/icons";
import {
  deleteUser,
  getUserList,
  grantOrRevokeUserLogin,
  sendEmail,
  userRegistration,
} from "../../../services/userServices";
import styles from "./styles.module.css";

const UserList = () => {
  const [userList, setUserList] = useState({ data: [], totalRecords: 0 });
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(5);

  useEffect(() => {
    callGetUserList();
  }, [start, limit]);

  const callGetUserList = async () => {
    try {
      setLoading(true);
      const result = await getUserList({
        query: { start, limit, type: "boolean" },
      });
      setLoading(false);
      setUserList(result);
    } catch (error) {
      notification.error({
        message: `${error?.status}: ${error?.statusText}`,
        description: error?.data?.message,
      });
      // setLoading(false);
    }
  };
  const callGrantOrRevokeUserLogin = async (record) => {
    try {
      // setLoading(true);

      console.log("grant revoke record", record);
      const payload = {
        id: record?.id,
        is_active: record?.is_active,
      };
      console.log("grant revoke payload", payload);
      const response = await grantOrRevokeUserLogin(payload);
      if (response?.message === "success")
        message.success("User Active Status Changed");
      else message.success("Something went Wrong");

      callGetUserList();
    } catch (error) {
      notification.error({
        message: `${error?.status}: ${error?.statusText}`,
        description: error?.data?.message,
      });
    }
  };

  const callDeleteUser = async (record) => {
    try {
      const payload = {
        id: record?.id,
      };

      const response = await deleteUser(payload);

      message.success("User is deleted");
      callGetUserList();
    } catch (error) {
      notification.error({
        message: `${error?.status}: ${error?.statusText}`,
        description: error?.data?.message,
      });
    }
  };

  const sendEmailFunction = async ({ id }) => {
    try {
      const payload = {
        id,
      };
      console.log("payload", payload);
      const response = await sendEmail({ payload });
      message.success("Invitation Email Sent");
    } catch (error) {
      notification.error({
        message: `${error?.status}: ${error?.statusText}`,
        description: error?.data?.message,
      });
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Is Verified",
      dataIndex: "is_verified",
      key: "_id",
      render: (text, record) => (record?.is_verified === true ? "Yes" : "No"),
      // render: (text, record) => `${record?.is_verified}`, I can use this example also to show true and false comming from database. as it is.
      //
    },
    {
      title: "User Status",
      dataIndex: "is_active",
      key: "_id",
      render: (text, record) =>
        record?.is_active === true ? "Active" : "Revoked",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Tooltip placement="topLeft" title="Invite user by email.">
            <Button
              onClick={() => sendEmailFunction({ id: record?._id })}
              type="primary"
              className={styles.button}
            >
              Invite
            </Button>
          </Tooltip>
          <Popconfirm
            title="Are you sure to delete this user?"
            onConfirm={() => callDeleteUser({ id: record?._id })}
            // onCancel={cancel}
            // getPopupContainer={(node) => node.parentNode}
            okText="Confirm"
            cancelText="Cancel"
            placement="leftTop"
            okButtonProps={{
              danger: true,
            }}
          >
            <Tooltip
              placement="topRight"
              title="Delete user account permanently."
            >
              <Button type="danger" className={styles.button}>
                Delete
              </Button>
            </Tooltip>
          </Popconfirm>

          {record?.is_active === true ? (
            <Popconfirm
              title="Are you sure to Revoke services of this user?"
              onConfirm={() =>
                callGrantOrRevokeUserLogin({
                  id: record?._id,
                  is_active: false,
                })
              }
              okText="Confirm"
              cancelText="Cancel"
              placement="leftTop"
              okButtonProps={{
                danger: true,
              }}
            >
              <Tooltip
                placement="topRight"
                title="After this user will not able to login and perform tasks."
              >
                <Button type="danger" className={styles.button}>
                  Revoke
                </Button>
              </Tooltip>
            </Popconfirm>
          ) : (
            <Tooltip
              placement="topRight"
              title="Allow user to login and perform task."
            >
              <Button
                type="primary"
                className={styles.button}
                style={{ backgroundColor: "green" }}
                onClick={() =>
                  callGrantOrRevokeUserLogin({
                    id: record?._id,
                    is_active: true,
                  })
                }
              >
                Grant
              </Button>
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];
  //Add new User Model Code Declaration Start Here
  const onFinish = async (values) => {
    try {
      setSubmitLoading(true);
      console.log("Success:", values);

      const payload = {
        name: values?.name,
        email: values?.email,
        password: values?.password,
      };
      console.log("grant revoke payload", payload);
      const response = await userRegistration(payload);

      message.success("User Registration Done");
      form.resetFields();
      callGetUserList();
      setSubmitLoading(false);
    } catch (error) {
      notification.error({
        message: `${error?.status}: ${error?.statusText}`,
        description: error?.data?.message,
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  //Add new User Model Code Declaration End Here

  const onShowSizeChange = (current, pageSize) => {
    setLimit(pageSize);
    // console.log(current, pageSize);
  };

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div>User List</div>
        <div className={styles.contentRight}>
          <Button type="primary" onClick={() => setVisible(true)}>
            Add New User
          </Button>
          <>
            {/* new User Model Code Start here  */}
            <Modal
              title="Add New User"
              visible={visible}
              // onOk={}
              cancelText="Close"
              onCancel={() => setVisible(false)}
              footer={[
                <Button
                  style={{
                    height: "40px",
                    borderRadius: "5px",
                  }}
                  loading={submitLoading}
                  type="primary"
                  // onClick={() => onFinish(form.getFieldValue(true))}
                  form="formModal"
                  key="submit"
                  htmlType="submit"
                >
                  Create New User Account
                </Button>,
                <Button
                  style={{
                    height: "40px",
                    borderRadius: "5px",
                  }}
                  ghost
                  // loading={loginLoading}
                  type="primary"
                  htmlType="submit"
                  onClick={() => setVisible(false)}
                  icon={<PoweroffOutlined />}
                >
                  Close
                </Button>,
              ]}
            >
              <Form
                name="basic"
                id="formModal"
                form={form}
                layout="vertical"
                requiredMark={false}
                autoComplete="off"
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  label="Full Name"
                  name="name"
                  className="lable"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Full Name!",
                    },
                    { whitespace: true },
                    { min: 3, message: "Name must be minimum 3 Character log" },
                  ]}
                  hasFeedback
                >
                  <Input size="large" style={{ borderRadius: "5px" }} />
                </Form.Item>
                <Form.Item
                  label="Your Email"
                  name="email"
                  className="lable"
                  extra="You will use this email to log into your account."
                  // noStyle
                  rules={[
                    {
                      required: true,
                      message:
                        "Please enter your email! Example john.doe@example.com",
                    },
                    {
                      type: "email",
                      message: "Please Enter a Valid email",
                    },
                  ]}
                  hasFeedback
                >
                  <Input
                    size="large"
                    placeholder="Enter your email"
                    style={{ borderRadius: "5px" }}
                    // id="error"
                  />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  className="lable"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                    {
                      min: 4,
                      message: "Password must be at least 4 characters long",
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password
                    size="large"
                    style={{ borderRadius: "5px" }}
                  />
                </Form.Item>
              </Form>
            </Modal>
            {/* new User Model Code End here  */}
          </>
        </div>
        <Table
          dataSource={userList?.data}
          columns={columns}
          loading={loading}
          rowKey={(record) => record?._id}
        />
        ;
        <Pagination
          total={userList?.totalRecords}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          showSizeChanger
          pageSize={limit}
          onShowSizeChange={onShowSizeChange}
          current={start / limit + 1}
          onChange={(page, pageSize) => {
            setStart((page - 1) * limit);
          }}
        />
      </div>
    </div>
  );
};

export default UserList;
